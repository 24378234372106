import SmsAnalytic from './SmsAnalytic'
import SMSList from './SMSList'

const DisplaySmsScreen = () => {
  return (
    <div>
      <SmsAnalytic/>
      <SMSList />
    </div>
  )
}

export default DisplaySmsScreen
