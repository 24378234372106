import { apiSlice } from './apiSlice';

export const smsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSMSById: builder.query({
      query: (id) => ({
        url: `/api/sms/${id}`,
      }),
      providesTags: ['SMS'],
    }),
    getTotalSMSByUserId: builder.query({
      query: (userID) => ({
        url: `/api/users/${userID}/total-sms`,
      }),
      providesTags: ['SMSCount'],
    }),
    getSMSByGymId: builder.query({
      query: ({ gymId, pageNo = 0, pageSize = 10, sortBy = 'sendDateTime', sortOrder = 'desc' }) => ({
        url: `/api/sms/gyms/${gymId}`,
        params: { pageNo, pageSize, sortBy, sortOrder },
      }),
      providesTags: ['SMS'],
    }),
    getMonthlySMSCountByGymId: builder.query({
      query: (gymId) => ({
        url: `/api/sms/gyms/${gymId}/monthly-count`,
      }),
      providesTags: ['SMS'],
    }),
    sendSMS: builder.mutation({
      query: (smsRequestDto) => ({
        url: '/api/sms/send',
        method: 'POST',
        body: smsRequestDto,
      }),
      invalidatesTags: ['SMS'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMonthlySMSCountByGymIdQuery,
  useGetSMSByGymIdQuery,
  useGetSMSByIdQuery,
  useGetTotalSMSByUserIdQuery,
  useSendSMSMutation

} = smsApiSlice;
