import React from 'react';

const PaginationControls = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onPrevious,
  onNext
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="flex justify-center items-center space-x-2">
      <button
        disabled={currentPage <= 0}
        onClick={onPrevious}
        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 disabled:opacity-50"
      >
        Previous
      </button>
      <button
        disabled={currentPage >= totalPages - 1}
        onClick={onNext}
        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 disabled:opacity-50"
      >
        Next
      </button>
    </div>
  );
};

export default PaginationControls;
