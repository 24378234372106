import { apiSlice } from "./apiSlice";

export const clientInvoiceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLastMembershipEndDateByClientId: builder.query({
      query: (clientId) => ({
        url: `/api/invoices/clients/${clientId}/latest-membership-end-date`,
      }),
      providesTags: ["Invoices", "Payments"],
    }),

    getInvoicesByClientId: builder.query({
      query: ({
        clientId,
        sortBy = "createdAt",
        sortOrder = "asc",
        pageNo = 0,
        pageSize = 25,
      }) => ({
        url: `/api/invoices/clients/${clientId}`,
        params: { sortBy, sortOrder, pageNo, pageSize },
      }),
      providesTags: ["Invoices", "Payments"],
    }),

    getAllInvoices: builder.query({
      query: () => ({
        url: `/api/invoices`,
      }),
      providesTags: ["Invoices"],
    }),

    getInvoiceById: builder.query({
      query: (id) => ({
        url: `/api/invoices/${id}`,
      }),
      providesTags: ["Invoices", "Payments"],
    }),

    createInvoice: builder.mutation({
      query: (invoice) => ({
        url: `/api/invoices`,
        method: "POST",
        body: invoice,
      }),
      invalidatesTags: ["Invoices"],
    }),

    updateInvoice: builder.mutation({
      query: ({ id, invoice }) => ({
        url: `/api/invoices/${id}`,
        method: "PUT",
        body: invoice,
      }),
      invalidatesTags: ["Invoices"],
    }),

    deleteInvoice: builder.mutation({
      query: (id) => ({
        url: `/api/invoices/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Invoices"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetLastMembershipEndDateByClientIdQuery,
  useGetInvoicesByClientIdQuery,
  useGetAllInvoicesQuery,
  useGetInvoiceByIdQuery,
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation,
  useDeleteInvoiceMutation,
} = clientInvoiceApiSlice;
