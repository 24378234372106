import { Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useGetDashboardCardDataQuery } from '../../redux/dashboardApiSlice';
import FetchWrapper from '../../components/FetchWrapper';  // Ensure you have this component ready
import { selectGym } from '../../redux/gymSlice';

const UserTypesPieChart = () => {
  const { gymId } = useSelector(selectGym);
  const { data, error, isLoading } = useGetDashboardCardDataQuery(gymId);

  const userTypeData = {
    labels: ["Expired Memberships", "Active Memberships", "Inactive Members"],
    datasets: [{
      label: 'User Types',
      data: data ? [data.totalExpiredMembers, data.totalActiveMembers, data.totalInactiveMembers] : [0, 0, 0],
      backgroundColor: [
        "rgba(255, 99, 132, 0.6)", // Red
        "rgba(54, 162, 235, 0.6)", // Blue
        "rgba(255, 206, 86, 0.6)"  // Yellow
      ],
    }],
  };

  const pieOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'User Types Distribution',
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <FetchWrapper isLoading={isLoading} isError={!!error} error={error}>
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4">User Types Distribution</h2>
        <div className="h-96">
          {data && <Pie data={userTypeData} options={pieOptions} />}
        </div>
      </div>
    </FetchWrapper>
  );
};

export default UserTypesPieChart;
