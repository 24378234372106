import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetGymsByUserQuery,
  useDeleteGymMutation,
} from "../../redux/gymApiSlice";
import { selectUser } from "../../redux/userSlice";
import { FaPlus, FaStore, FaEdit, FaTrash, FaDollarSign } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import FetchWrapper from "../../components/FetchWrapper";
import ConfirmDialog from "../../components/ConfirmDialog"; // Assuming ConfirmDialog is imported
import { selectGym, setGym } from "../../redux/gymSlice";
import { showAlert } from "../../redux/alertSlice";
import useSnackbar from "../../hooks/useSnackbar"; // Import useSnackbar hook

const GymScreen = () => {
  const { showSnackbar, SnackbarComponent } = useSnackbar(); // Use Snackbar hook
  const { userId } = useSelector(selectUser); // Get the current user's ID
  const activeGym = useSelector(selectGym); // Get the active gym from the store

  const {
    data: gyms,
    isLoading,
    isError,
    error,
  } = useGetGymsByUserQuery({
    userId,
    pageNo: 0,
    pageSize: 25,
    sortBy: "gymName",
    sortOrder: "asc",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteGym] = useDeleteGymMutation(); // Use mutation hook
  const [selectedGymId, setSelectedGymId] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);

  // Handle navigation to the create gym/branch screen
  const handleAddBranch = () => {
    navigate("add"); // Adjust this route as necessary
  };

  // Handle edit gym
  const handleEditGym = (gymId) => {
    navigate(`edit/${gymId}`); // Navigate to the edit page
  };
  const handleAddPricing = (gymId) => {
    navigate(`pricing/${gymId}`); // Navigate to the edit page
  };

  const handleCardClick = (gymId) => {
    navigate(`${gymId}`); // Navigate to the edit page
  };

  // Handle delete gym
  const handleDeleteGym = async () => {
    try {
      await deleteGym(selectedGymId).unwrap();
      console.log(`Gym with ID ${selectedGymId} deleted`);
      showSnackbar("Gym deleted successfully", "success"); // Show success snackbar
    } catch (error) {
      console.error("Failed to delete gym:", error);
      showSnackbar("Failed to delete gym. Please try again.", "error"); // Show error snackbar
    } finally {
      setDialogOpen(false); // Close the confirmation dialog after deleting
    }
  };

  // Open confirmation dialog
  const confirmDeleteGym = (gymId) => {
    if (activeGym.gymId === gymId) {
      // If trying to delete the active gym, show an alert
      dispatch(
        showAlert({
          message:
            "Cannot delete the active gym. Please select another gym before deleting.",
          action: null,
        })
      );
      showSnackbar(
        "Cannot delete the active gym. Please select another gym before deleting.",
        "warning"
      ); // Show warning snackbar
      return; // Prevent further execution
    }
    setSelectedGymId(gymId);
    setDialogOpen(true);
  };

  return (
    <div className="container mx-auto p-4 min-h-[90vh] flex flex-col items-center justify-center">
      <FetchWrapper isLoading={isLoading} isError={isError} error={error}>
        {gyms && gyms.content.length > 0 ? (
          <div className="flex flex-col gap-6 items-center justify-center">
            {/* Display Gym(s) */}
            <div className="flex flex-wrap justify-center gap-6 items-center">
              {gyms.content.map((gym) => (
                <GymCard
                  key={gym.id}
                  gym={gym}
                  onAddPricing={() => handleAddPricing(gym.id)}
                  onEdit={() => handleEditGym(gym.id)}
                  onDelete={() => confirmDeleteGym(gym.id)}
                  onClick={() => handleCardClick(gym.id)}
                  isActive={activeGym.gymId === gym.id}
                />
              ))}

              <AddGymButton onClick={handleAddBranch} text="Add More Branch" />
            </div>
          </div>
        ) : (
          <AddGymButton onClick={handleAddBranch} text="Add Gym Branch" />
        )}
      </FetchWrapper>

      {/* Confirm Delete Dialog */}
      <ConfirmDialog
        isOpen={isDialogOpen}
        title="Delete Gym"
        message="Are you sure you want to delete this gym? This action cannot be undone."
        onConfirm={(e) => {
          e.stopPropagation(); // Prevent event bubbling
          handleDeleteGym();
        }}
        onCancel={(e) => {
          e.stopPropagation(); // Prevent event bubbling
          setDialogOpen(false);
        }}
      />

      {/* Snackbar Component */}
      <SnackbarComponent />
    </div>
  );
};
// Mini Component for Gym Card
const GymCard = ({
  gym,
  onAddPricing,
  onEdit,
  onDelete,
  onClick,
  isActive,
}) => {
  const dispatch = useDispatch();

  return (
    <div
      onClick={onClick}
      className="group card card-compact w-64 bg-base-100 shadow-xl relative cursor-pointer"
    >
      <figure>
        {gym.gymLogoImageUrl ? (
          <img
            src={gym.gymLogoImageUrl}
            alt={`${gym.gymName} logo`}
            className="w-full h-48 object-cover"
          />
        ) : (
          <div className="w-full h-48 bg-gray-300 flex items-center justify-center text-gray-500">
            <FaStore className="text-6xl" />
          </div>
        )}
      </figure>
      <div className="card-body">
        <h3 className="card-title text-lg font-bold text-gray-800">
          {gym.gymName}
        </h3>
        <p className="text-gray-600">{gym.branch}</p>

        {isActive && (
          <div className="absolute top-2 left-2 bg-green-500 text-white text-sm px-2 py-1 rounded">
            Active
          </div>
        )}

        <div className="mt-4">
          <button
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                setGym({
                  gymId: gym.id,
                  gymBranch: gym.branch,
                  gymName: gym.gymName,
                  gymAddress: gym.address,
                  gymContact: gym.contactNumber.join(", "), // Convert array to a comma-separated string
                  gymEmail: gym.emailAddress, // Add email address if needed
                  gymLogourl: gym.gymLogoImageUrl, // Assuming you have a logo URL field
                })
              );
            }}
            className="w-full bg-sky-500 text-white py-2 px-4 rounded-lg hover:bg-sky-600 transition-colors"
          >
            Select Gym
          </button>
        </div>

        <div className="absolute top-2 right-2 flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onAddPricing();
            }}
            className="btn bg-green-400 btn-square btn-sm text-gray-700 hover:text-primary"
          >
            <FaDollarSign className="text-lg" />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
            className="btn bg-blue-400 btn-square btn-sm text-gray-700 hover:text-primary"
          >
            <FaEdit />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            className="btn bg-red-400 btn-square btn-sm text-gray-700 hover:text-red-500"
          >
            <FaTrash />
          </button>
        </div>
      </div>
    </div>
  );
};

// Mini Component for Add Gym Button
const AddGymButton = ({ onClick, text }) => (
  <div className="flex flex-col items-center justify-center min-h-96">
    <button
      onClick={onClick}
      className="btn btn-square  bg-sky-500  text-white hover:bg-sky-600 w-24 h-24 flex items-center justify-center"
    >
      <FaPlus className="text-4xl" />
    </button>
    <p className="mt-4">{text}</p>
  </div>
);

export default GymScreen;
