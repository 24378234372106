import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import RouteConfig from "../config/RouteConfig";
import { selectGym } from "../redux/gymSlice"; // Adjust the import path as needed
import ToggleAdminPanelButton from "../components/ToggleAdminPanelButton";
import { selectUser } from "../redux/userSlice";

const protectedRoutes = [
  { path: "/home", label: "Home" },
  { path: RouteConfig.GYM_CATEGORY, label: "Gym" },
  { path: RouteConfig.CLIENTS, label: "Clients" },
  { path: RouteConfig.SCHEDULE, label: "Schedule" },
  { path: RouteConfig.SMS, label: "Sms" },
  { path: RouteConfig.PROFILE, label: "Profile" },
];

const ProtectedNavBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { gymBranch, gymName } = useSelector(selectGym); // Get gym info from Redux store
  const { superAdmin } = useSelector(selectUser); // Get gym info from Redux store

  const toggleDrawer = (open) => () => setDrawerOpen(open);

  // Mini component defined within the same file
  const NavItem = ({ to, label }) => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive
          ? "text-primary border-b-2 border-primary"
          : "text-base hover:text-primary"
      }
    >
      {label}
    </NavLink>
  );

  return (
    <nav className="bg-base-100 sticky top-0 z-50 shadow-md">
      <div className="container mx-auto px-6 lg:px-16 py-3 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <div
            className="text-2xl font-bold text-primary"
            aria-label="App Icon"
          >
            {gymName}
          </div>

          {!superAdmin && (
            <div className="hidden sm:flex  sm:items-center">
              {gymBranch && (
                <div className=" bg-green-500 text-white text-sm px-2 py-1 rounded">
                  {gymBranch}
                </div>
              )}
            </div>
          )}
          <ToggleAdminPanelButton />
        </div>
        <div className="flex items-center">
          {/* Desktop Navigation Links */}
          <div className="hidden md:flex space-x-4">
            {protectedRoutes.map((route) => (
              <NavItem key={route.label} to={route.path} label={route.label} />
            ))}
            <NavItem to={RouteConfig.LOGOUT} label="Logout" />
          </div>

          {/* Mobile Drawer Toggle Button */}
          <div className="md:hidden">
            <button
              onClick={toggleDrawer(true)}
              className="btn btn-primary"
              aria-label="Open Menu"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Drawer */}
      {drawerOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50"
          onClick={toggleDrawer(false)}
        >
          <div
            className="bg-base-100 w-64 h-full absolute right-0 top-0 p-6"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-col space-y-4">
              {protectedRoutes.map((route) => (
                <NavItem
                  key={route.label}
                  to={route.path}
                  label={route.label}
                />
              ))}
              <NavItem to={RouteConfig.LOGOUT} label="LogOut" />
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default ProtectedNavBar;
