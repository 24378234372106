import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectUser } from '../redux/userSlice';
import RouteConfig from '../config/RouteConfig';

const PublicRoute = () => {
  const user = useSelector(selectUser);

  if (user.isAuthenticated) {
    return <Navigate to={RouteConfig.GYM_SELECTOR} replace />;
  }


  return <Outlet />;
};

export default PublicRoute;
