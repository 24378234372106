import { apiSlice } from "./apiSlice";

export const gymApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Fetch gyms by user ID with pagination
    getGymsByUser: builder.query({
      query: ({
        userId,
        pageNo = 0,
        pageSize = 25,
        sortBy = "gymName",
        sortOrder = "asc",
      }) => ({
        url: `/api/gyms/users/${userId}`,
        method: "GET",
        params: { pageNo, pageSize, sortBy, sortOrder },
      }),
      providesTags: ["Gyms"],
    }),

    // Fetch a single gym by its ID
    getGymById: builder.query({
      query: (gymId) => ({
        url: `/api/gyms/${gymId}`,
        method: "GET",
      }),
      providesTags: ["Gyms"],
    }),

    // Create a new gym
    createGym: builder.mutation({
      query: ({ gymData, wifiFile, logoFile }) => {
        const formData = new FormData();
        Object.keys(gymData).forEach((key) => {
          if (gymData[key] !== null && gymData[key] !== undefined) {
            formData.append(key, gymData[key]);
          }
        });
        if (wifiFile) formData.append("wifiFile", wifiFile);
        if (logoFile) formData.append("logoFile", logoFile);

        return {
          url: "/api/gyms",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Gyms"],
    }),

    // Update a gym by ID
    updateGym: builder.mutation({
      query: ({ gymId, gymData, wifiFile, logoFile }) => {
        const formData = new FormData();
        Object.keys(gymData).forEach((key) => {
          if (gymData[key] !== null && gymData[key] !== undefined) {
            formData.append(key, gymData[key]);
          }
        });
        if (wifiFile) formData.append("wifiFile", wifiFile);
        if (logoFile) formData.append("logoFile", logoFile);

        return {
          url: `/api/gyms/${gymId}`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: ["Gyms"],
    }),

    // Soft delete a gym by ID
    deleteGym: builder.mutation({
      query: (gymId) => ({
        url: `/api/gyms/${gymId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Gyms"],
    }),

    // Search gyms with pagination and optional filters
    searchGyms: builder.query({
      query: ({
        userId,
        gymName,
        emailAddress,
        websiteURL,
        minLatitude,
        maxLatitude,
        minLongitude,
        maxLongitude,
        pageNo = 0,
        pageSize = 25,
        sortBy = "gymName",
        sortOrder = "asc",
      }) => ({
        url: "/api/gyms/search",
        method: "GET",
        params: {
          userId,
          gymName,
          emailAddress,
          websiteURL,
          minLatitude,
          maxLatitude,
          minLongitude,
          maxLongitude,
          pageNo,
          pageSize,
          sortBy,
          sortOrder,
        },
      }),
      providesTags: ["Gyms"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetGymsByUserQuery,
  useGetGymByIdQuery,
  useCreateGymMutation,
  useUpdateGymMutation,
  useDeleteGymMutation,
  useSearchGymsQuery,
} = gymApiSlice;
