import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import CustomFileInput from "../../components/CustomFileInput";
import useSnackbar from "../../hooks/useSnackbar";
import {
  FaUserAlt,
  FaPhone,
  FaHome,
  FaEnvelope,
  FaExclamationTriangle,
  FaBirthdayCake,
  FaClipboardList,
  FaCalendarAlt,
} from "react-icons/fa";
import FormCardWrapper from "../../components/FormCardWrapper";
import FormField from "../../components/FormField";

const ClientForm = ({ initialData, onSubmit, isLoading }) => {
  const [clientData, setClientData] = useState(initialData);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  useEffect(() => {
    setClientData(initialData);
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setClientData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFileChange = (selectedFile) => {
    setFile(selectedFile);
  };

  const validate = () => {
    let tempErrors = {};
    if (!clientData.name) tempErrors.name = "Name is required.";
    if (!clientData.phone) tempErrors.phone = "Phone is required.";
    if (!clientData.gender) tempErrors.gender = "Gender is required."; // Validation for gender
   
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      await onSubmit({ clientDto: clientData, file });
      showSnackbar("Client saved successfully!", "success");
      setClientData(initialData);
      setFile(null);
    } catch (err) {
      console.error("Failed to save client:", err);
      showSnackbar(
        "Failed to save client: " + (err.data?.message || "Unknown error"),
        "error"
      );
    }
  };

  const addMonths = (months) => {
    const newDate = dayjs().add(months, "month").format("YYYY-MM-DD");
    setClientData((prev) => ({
      ...prev,
      membershipEndDate: newDate,
    }));
  };

  return (
    <div className="container mx-auto">
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-1 md:grid-cols-2 gap-4"
      >
        <BasicInfo
          inputFields={inputFields}
          clientData={clientData}
          handleChange={handleChange}
          errors={errors}
          addMonths={addMonths}
        />
        <ImageUploadSection handleFileChange={handleFileChange} file={file} />
        <div className="col-span-1 md:col-span-2 flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 text-white btn max-w-lg w-full"
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save Client"}
          </button>
        </div>
      </form>
      <SnackbarComponent />
    </div>
  );
};

export default ClientForm;

const BasicInfo = ({
  inputFields,
  clientData,
  handleChange,
  errors,
  addMonths,
}) => (
  <FormCardWrapper title="Basic Info">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {inputFields.map((field, index) => {
        if (field.name === "gender") {
          return (
            <div key={index} className="mb-4 flex flex-col">
              <label className="text-gray-700 flex items-center mb-1">
                {field.icon}
                {field.label}
              </label>
              <div className="flex w-full border  rounded space-x-4  bg-white pl-2 py-2 ">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="gender"
                    value="MALE"
                    checked={clientData.gender === "MALE"}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Male
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="gender"
                    value="FEMALE"
                    checked={clientData.gender === "FEMALE"}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Female
                </label>
              </div>
              {errors.gender && (
                <div className="text-red-500">{errors.gender}</div>
              )}
            </div>
          );
        }

        return (
          <FormField
            key={index}
            field={field}
            value={clientData[field.name] ?? ""}
            onChange={handleChange}
            error={errors[field.name]}
          />
        );
      })}
      <div className="flex flex-wrap gap-4">
        {["1", "3", "6", "12"].map((month) => (
          <button
            key={month}
            type="button"
            className="btn btn-outline btn-primary"
            onClick={() => addMonths(month)}
          >
            Add {month} Month{month > 1 ? "s" : ""}
          </button>
        ))}
      </div>
    </div>
  </FormCardWrapper>
);

const ImageUploadSection = ({ handleFileChange, file }) => (
  <FormCardWrapper title="Profile Image">
    <div className="flex justify-center">
      <CustomFileInput
        name="Change Profile"
        onChange={handleFileChange}
        reset={!file}
      />
    </div>
  </FormCardWrapper>
);

const inputFields = [
  {
    label: "Name",
    name: "name",
    type: "text",
    icon: <FaUserAlt className="text-xl text-indigo-500 mr-2" />,
    required: true,
  },
  {
    label: "Gender",
    name: "gender",
    type: "radio",
    icon: <FaUserAlt className="text-xl text-indigo-500 mr-2" />,
    required: true,
  },
  {
    label: "Phone",
    name: "phone",
    type: "text",
    icon: <FaPhone className="text-xl text-green-500 mr-2" />,
    required: true,
  },

  {
    label: "Address",
    name: "address",
    type: "text",
    icon: <FaHome className="text-xl text-blue-500 mr-2" />,
  },
  {
    label: "Email",
    name: "email",
    type: "email",
    icon: <FaEnvelope className="text-xl text-blue-500 mr-2" />,
  },
  {
    label: "Emergency Contact",
    name: "emergencyContact",
    type: "text",
    icon: <FaExclamationTriangle className="text-xl text-red-500 mr-2" />,
    required: false,
  },
  {
    label: "Date of Birth",
    name: "dateOfBirth",
    type: "date",
    icon: <FaBirthdayCake className="text-xl text-yellow-500 mr-2" />,
  },
  {
    label: "Joined Date",
    name: "joinedDate",
    type: "date",
    icon: <FaClipboardList className="text-xl text-purple-500 mr-2" />,
  },

  {
    label: "Membership End Date",
    name: "membershipEndDate",
    type: "date",
    icon: <FaCalendarAlt className="text-xl text-orange-500 mr-2" />,
    required: false,
  },

  {
    label: "Notes",
    name: "notes",
    type: "textarea",
    className: "col-span-1 md:col-span-2",
    rows: 3,
  },
];
