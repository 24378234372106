import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/userSlice";
import { clearGym } from "../../redux/gymSlice";
import { useNavigate } from "react-router-dom";
import RouteConfig from "../../config/RouteConfig";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(RouteConfig.LOGIN);
    dispatch(logout());
    dispatch(clearGym());
  }, [dispatch, navigate]);

  return <></>;
};

export default Logout;
