import React, { useState } from "react";
import { FaCheck, FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDeleteInvoiceMutation } from "../../../redux/clientInvoiceApiSlice";
import Table from "../../../components/Table";
import ConfirmDialog from "../../../components/ConfirmDialog";
import dayjs from "dayjs";
import useSnackbar from "../../../hooks/useSnackbar";

// Mini Component for Action Buttons
const ActionButtons = ({ clientId, invoiceId, onEdit, onDelete }) => (
  <div className="flex items-center space-x-2">
    <button
      onClick={() => onEdit(clientId, invoiceId)}
      className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 hidden md:inline"
    >
      Edit
    </button>
    <button
      onClick={() => onDelete(invoiceId)}
      className="bg-red-500 text-white p-2 rounded hover:bg-red-600 hidden md:inline"
    >
      Delete
    </button>
    <button
      onClick={() => onEdit(clientId, invoiceId)}
      className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 md:hidden"
    >
      <FaEdit />
    </button>
    <button
      onClick={() => onDelete(invoiceId)}
      className="bg-red-500 text-white p-2 rounded hover:bg-red-600 md:hidden"
    >
      <FaTrash />
    </button>
  </div>
);

const InvoicesTable = ({
  clientId,
  invoices,
  pageNo,
  pageSize,
  totalPages,
  onPageChange,
  onPageSizeChange,
}) => {
  const navigate = useNavigate();
  const [deleteInvoice] = useDeleteInvoiceMutation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const { SnackbarComponent, showSnackbar } = useSnackbar();

  const handleEdit = (clientId, invoiceId) => {
    // <Route path=":clientId/edit-invoices/:invoiceId" element={<EditInvoice />} />

    navigate(`invoices/${invoiceId}/edit`);
  };

  const handleDelete = async (invoiceId) => {
    try {
      await deleteInvoice(invoiceId).unwrap();
    } catch (error) {
      showSnackbar(error.data.message);
      console.error("Failed to delete invoice:", error);
    }
    setIsDialogOpen(false);
  };

  const openDialog = (invoiceId) => {
    setSelectedInvoiceId(invoiceId);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedInvoiceId(null);
  };

  const headers = {
    values: [
      "Plan Name & Duration",
      "Total & Discounted Amount",
      "Amount Paid",
      "Amount Due",
    ],
  };

  const rows = invoices.content.map((invoice) => {
    // Calculate the number of months between the dates
    const startDate = dayjs(invoice.membershipStartDate);
    const endDate = dayjs(invoice.membershipEndDate);
    const durationInMonths = endDate.diff(startDate, "month", true).toFixed(1); // true for floating point result

    return {
      id: invoice.id,
      "Plan Name & Duration": (
        <div>
          <div>{invoice.pricingPlanDto.planName}</div>
          <div className="text-xs text-gray-500">{durationInMonths} months</div>
        </div>
      ),
      "Total & Discounted Amount": (
        <div>
          <div>Total: Rs {invoice.totalAmount}</div>
          <div>SP: Rs {invoice.discountedAmount}</div>
        </div>
      ),
      "Amount Paid": (
        <div className="badge badge-success text-white font-semibold text-sm px-4 py-1 min-w-[80px] text-center">
          Rs {invoice.amountPaid}
        </div>
      ),
      "Amount Due":
        invoice.amountDue === 0 ? (
          <div className="badge badge-success text-white font-semibold text-sm px-4 py-1 min-w-[80px] text-center">
            <FaCheck />
            <span className="ml-2">Cleared</span>
          </div>
        ) : (
          <div className="badge badge-error font-semibold text-sm px-4 py-1 min-w-[80px]  text-center">
            <span>Rs </span>
            <span>{invoice.amountDue}</span>
          </div>
        ),
    };
  });

  const handleRowClick = (invoice) => {
    navigate(`invoices/${invoice.id}`);
  };

  return (
    <div className="mt-10">
      <h2 className="text-2xl font-semibold mb-4">Invoices</h2>
      <Table
        headers={headers}
        rows={rows}
        onRowClick={handleRowClick}
        actions={(row) => (
          <ActionButtons
            clientId={clientId}
            invoiceId={row.id}
            onEdit={handleEdit}
            onDelete={() => openDialog(row.id)}
          />
        )}
      />
      <div className="mt-4 flex flex-col md:flex-row justify-between items-center">
        <div className="mb-4 md:mb-0">
          <label htmlFor="pageSize" className="mr-2">
            Rows per page:
          </label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={onPageSizeChange}
            className="border rounded p-1"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className="flex items-center space-x-2">
          <button
            className="p-2 border rounded disabled:opacity-50"
            onClick={() => onPageChange(pageNo - 1)}
            disabled={pageNo === 0}
          >
            Previous
          </button>
          <span>
            Page {pageNo + 1} of {totalPages}
          </span>
          <button
            className="p-2 border rounded disabled:opacity-50"
            onClick={() => onPageChange(pageNo + 1)}
            disabled={pageNo >= totalPages - 1}
          >
            Next
          </button>
        </div>
      </div>

      <SnackbarComponent />

      <ConfirmDialog
        isOpen={isDialogOpen}
        title="Delete Invoice"
        message="Are you sure you want to delete this invoice?"
        onConfirm={() => handleDelete(selectedInvoiceId)}
        onCancel={closeDialog}
      />
    </div>
  );
};

export default InvoicesTable;
