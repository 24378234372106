import { NavLink } from "react-router-dom";
import RouteConfig from "../config/RouteConfig";
import ToggleAdminPanelButton from "../components/ToggleAdminPanelButton";

const routes = [
  { path: RouteConfig.ADMIN_HOME, label: "Home" },
  { path: RouteConfig.Manage_Users, label: "Users" },
  { path: RouteConfig.MANAGE_Gyms, label: "Gyms" },
  { path: RouteConfig.ADMIN_REPORTS, label: "Reports" },
  { path: RouteConfig.LOGOUT, label: "logout" },
];

const AdminNavBar = () => {
  return (
    <nav className="bg-white sticky top-0 z-50 shadow-md">
      <div className="container mx-auto px-6 lg:px-16 py-3 flex justify-between items-center">
        <div className="hidden sm:block">
          <NavLink
            to="/"
            className="text-purple-600 text-2xl font-bold"
            aria-label="App Icon"
          >
            Admin Panel
          </NavLink>
          <ToggleAdminPanelButton />
        </div>
        <div className="flex flex-1 justify-center sm:justify-end items-center space-x-4">
          {routes.map((route) => (
            <NavLink
              to={route.path}
              key={route.label}
              className={({ isActive }) =>
                isActive
                  ? "text-black border-b-2 border-purple-600"
                  : "text-black hover:text-gray-700"
              }
            >
              {route.label}
            </NavLink>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default AdminNavBar;
