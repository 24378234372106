import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FetchWrapper from "../../components/FetchWrapper";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectGym } from "../../redux/gymSlice";
import { useGetSMSByGymIdQuery } from "../../redux/smsApiSlice";
import Table from "../../components/Table";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SMSList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();

  const { gymId } = useSelector(selectGym);

  const [order, setOrder] = useState(query.get("sortOrder") || "desc");
  const [orderBy, setOrderBy] = useState(query.get("sortBy") || "sendDateTime");
  const [page, setPage] = useState(parseInt(query.get("page") || 0, 10));
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(query.get("rowsPerPage") || 10, 10)
  );

  const {
    data: smsData,
    isLoading,
    isError,
    error,
  } = useGetSMSByGymIdQuery({
    gymId: gymId,
    pageNo: page,
    pageSize: rowsPerPage,
    sortBy: orderBy,
    sortOrder: order,
  });

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    updateURL({ sortOrder: newOrder, sortBy: property });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    updateURL({ page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page when rows per page change
    updateURL({ rowsPerPage: newRowsPerPage, page: 0 });
  };

  const updateURL = (params) => {
    const searchParams = new URLSearchParams(location.search);
    Object.keys(params).forEach((key) => {
      if (params[key] !== undefined) {
        searchParams.set(key, params[key]);
      }
    });
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">SMS List</h1>

      <FetchWrapper
        isError={isError}
        error={error}
        isLoading={isLoading}
        isEmptyList={smsData?.content?.length === 0}
      >
        {smsData?.content ? (
          <>
            <SmsTable
              smsList={smsData.content}
              orderBy={orderBy}
              order={order}
              onSort={handleRequestSort}
              navigate={navigate}
            />

            <PaginationControls
              page={page}
              rowsPerPage={rowsPerPage}
              totalElements={smsData.paginationResponse.totalElements}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : null}
      </FetchWrapper>
    </div>
  );
};

const SmsTable = ({ smsList, orderBy, order, onSort, navigate }) => {
  // Define headers for the table
  const headers = {
    values: ["Client", "Content", "Phone", "Send Date", "SMS Cost"],
    sortable: ["client", "sendDateTime"], // Ensure these keys match the keys in rows
  };

  // Map smsList to rows
  const rows = smsList.map((sms) => ({
    id: sms.id,
    client: sms.client ? sms.client.name : "No Client",
    content: sms.content,
    phone: sms.phone,
    sendDateTime: (
      <div style={{ minWidth: "80px", whiteSpace: "nowrap" }}>
        <div>{dayjs(sms.sendDateTime).format("YYYY-MM-DD")}</div>
        <div>{dayjs(sms.sendDateTime).format("HH:mm:ss")}</div>
      </div>
    ),
    smsCost: <span className="text-red-600">- {sms.smsCost}</span>,
  }));

  return (
    <Table
      headers={headers}
      rows={rows}
      onSort={onSort}
      orderBy={orderBy}
      order={order}
    />
  );
};

const PaginationControls = ({
  page,
  rowsPerPage,
  totalElements,
  onPageChange,
  onRowsPerPageChange,
}) => (
  <div className="flex flex-col md:flex-row justify-between items-center py-2 space-y-2 md:space-y-0">
    <div className="flex items-center">
      <span>Rows per page:</span>
      <select
        value={rowsPerPage}
        onChange={onRowsPerPageChange}
        className="ml-2 p-1 border rounded"
      >
        {[5, 10, 25, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </select>
    </div>
    <div className="flex items-center space-x-2">
      <span>
        Page: {page + 1} of {Math.ceil(totalElements / rowsPerPage)}
      </span>
      <button
        onClick={() => onPageChange(page - 1)}
        disabled={page === 0}
        className="p-1 border rounded"
      >
        Previous
      </button>
      <button
        onClick={() => onPageChange(page + 1)}
        disabled={page >= Math.ceil(totalElements / rowsPerPage) - 1}
        className="p-1 border rounded"
      >
        Next
      </button>
    </div>
  </div>
);

export default SMSList;
