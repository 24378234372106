import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useGetUserByIdQuery } from "../../redux/userApiSlice";
import FetchWrapper from "../../components/FetchWrapper";
import {
  FaPhone,
  FaMapMarkerAlt,
  FaAddressCard,
  FaUserShield,
  FaCheckCircle,
  FaSms,
  FaCalendarAlt,
  FaClock,
  FaEdit,
} from "react-icons/fa";
import FormCardWrapper from "../../components/FormCardWrapper";

const ManageUserProfile = () => {
  const { userId } = useParams(); // Assume the user ID is passed via URL
  const { data: user, isLoading, isError, error } = useGetUserByIdQuery(userId);
  const navigate = useNavigate();

  return (
    <FetchWrapper
      isLoading={isLoading}
      isError={isError}
      error={error}
      isEmptyList={!user} // Check if user data is not available
      emptyListInfo="user"
    >
      {user && (
        <div className="container mx-auto p-6  ">
          <FormCardWrapper title={"User Info"}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex items-center space-x-3">
                <FaPhone className="text-blue-500" />
                <div>
                  <span className="block font-semibold">Name:</span>
                  <span className="text-gray-700">{user.name}</span>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <FaPhone className="text-blue-500" />
                <div>
                  <span className="block font-semibold">Phone:</span>
                  <span className="text-gray-700">{user.phone}</span>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <FaMapMarkerAlt className="text-green-500" />
                <div>
                  <span className="block font-semibold">District:</span>
                  <span className="text-gray-700">{user.district}</span>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <FaAddressCard className="text-red-500" />
                <div>
                  <span className="block font-semibold">Address:</span>
                  <span className="text-gray-700">{user.address}</span>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <FaUserShield className="text-purple-500" />
                <div>
                  <span className="block font-semibold">Role:</span>
                  <span className="text-gray-700">{user.role}</span>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <FaCheckCircle
                  className={`text-${user.verified ? "green" : "red"}-500`}
                />
                <div>
                  <span className="block font-semibold">Verified:</span>
                  <span className="text-gray-700">
                    {user.verified ? "Yes" : "No"}
                  </span>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <FaSms className="text-yellow-500" />
                <div>
                  <span className="block font-semibold">Total SMS:</span>
                  <span className="text-gray-700">{user.totalSms}</span>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <FaCalendarAlt className="text-teal-500" />
                <div>
                  <span className="block font-semibold">Payment Expiry:</span>
                  <span className="text-gray-700">
                    {dayjs(user.paymentExpiryDate).format("YYYY-MM-DD")}
                  </span>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <FaClock className="text-indigo-500" />
                <div>
                  <span className="block font-semibold">Created At:</span>
                  <span className="text-gray-700">
                    {dayjs(user.createdAt).format("YYYY-MM-DD HH:mm")}
                  </span>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <FaClock className="text-indigo-500" />
                <div>
                  <span className="block font-semibold">Updated At:</span>
                  <span className="text-gray-700">
                    {dayjs(user.updatedAt).format("YYYY-MM-DD HH:mm")}
                  </span>
                </div>
              </div>
            </div>
          </FormCardWrapper>

          <div className="mt-8 flex justify-center flex-col md:flex-row">
            <button
              className="bg-purple-500 text-white w-64 py-2 rounded-lg shadow-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-400 mb-4 md:mb-0 md:mr-4"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`edit`);
              }}
            >
              <FaEdit className="inline mr-2" />
              Edit Client
            </button>
          </div>
        </div>
      )}

    </FetchWrapper>
  );
};

export default ManageUserProfile;
