import React from "react";
import { PhoneIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { FaWhatsapp, FaPrayingHands } from "react-icons/fa";

const Contact = () => {
  const phoneNumber = "9762274458";
  const email = "Comming soon...";
  const whatsappNumber = "9762274458";

  return (
    <div
      className="relative pt-10"
      style={{ minHeight: `calc(100vh - var(--navbar-height))` }}
    >
      <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-500 opacity-50"></div>
      <div className="relative container mx-auto px-6 lg:px-8">
        <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">
          Contact Us
        </h2>
        <div className="flex flex-col items-center space-y-6 mb-8 w-full">
          <div className="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-md w-full max-w-md h-24">
            <PhoneIcon className="w-6 h-6 text-blue-600" />
            <a href={`tel:${phoneNumber}`} className="text-lg text-gray-700">
              {phoneNumber}
            </a>
          </div>
          <div className="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-md w-full max-w-md h-24">
            <FaWhatsapp className="w-6 h-6 text-green-500" />
            <a
              href={`https://wa.me/${whatsappNumber}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-lg text-gray-700"
            >
              WhatsApp Us
            </a>
          </div>
          {/* <div className="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-md w-full max-w-md h-24">
            <EnvelopeIcon className="w-6 h-6 text-blue-600" />
            <a href={`mailto:${email}`} className="text-lg text-gray-700">
              {email}
            </a>
          </div> */}
        </div>
        <div className="mt-4 text-center text-gray-700 text-lg flex flex-col items-center">
          <FaPrayingHands className="w-10 h-10 text-purple-600 mb-2" />
          <p>We are waiting for your call or message!</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
