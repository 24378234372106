import React, { useEffect, useState } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import AvatarEditor from "react-avatar-editor";

const CustomFileInput = ({ name, onChange, reset }) => {
  const [filePreview, setFilePreview] = useState(null);
  const [value, setValue] = useState(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(1);
  const [cropOption, setCropOption] = useState("squareLogo");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.type.includes("image")) {
      setValue(selectedFile);
      setFilePreview(URL.createObjectURL(selectedFile));
      setIsCropModalOpen(true); // Open the crop modal
    } else {
      event.target.value = null;
      onChange(null);
      setFilePreview("");
      alert("Please select an image file.");
    }
  };

  useEffect(() => {
    if (reset) {
      setFilePreview("");
      setValue(null);
      setCroppedImage(null);
    }
  }, [reset]);

  const handleSave = () => {
    if (editor) {
      // Get the canvas from AvatarEditor and convert it to a blob
      editor.getImageScaledToCanvas().toBlob((blob) => {
        const file = new File([blob], value.name, { type: "image/jpeg" });
        onChange(file);
        setCroppedImage(URL.createObjectURL(blob));
        setIsCropModalOpen(false);
      }, "image/jpeg");
    }
  };

  // Clear the selected image on cancel
  const handleCancel = () => {
    setFilePreview("");
    setValue(null);
    setIsCropModalOpen(false);
  };

  // Determine dimensions based on selected crop option
  const getDimensions = () => {
    switch (cropOption) {
      case "squareLogo":
        return { width: 250, height: 250 }; // 1:1 aspect ratio
      case "rectangularLogo":
        return { width: 400, height: 100 }; // 4:1 aspect ratio
      default:
        return { width: 250, height: 250 };
    }
  };

  return (
    <div className="flex flex-col items-center">
      <label
        htmlFor={name}
        className="cursor-pointer flex flex-col items-center"
      >
        <input
          id={name}
          type="file"
          onChange={handleFileChange}
          className="hidden"
          accept="image/*"
        />
        <div className="bg-gray-200 p-2 rounded-full">
          <FaCloudUploadAlt className="text-gray-600" size={30} />
        </div>
        <span className="mt-2 text-gray-600">{name}</span>
      </label>
      {croppedImage && (
        <img
          src={croppedImage}
          alt="Preview"
          className="w-32 h-32 mt-4 rounded-full object-cover"
        />
      )}
      <p className="mt-2 text-sm text-gray-600">
        {value ? value.name : "No file chosen"}
      </p>

      {/* Crop Modal */}
      {isCropModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
          <div
            className="bg-white p-4 rounded-lg shadow-lg max-w-full"
            style={{
              width: Math.min(
                getDimensions().width + 200,
                window.innerWidth - 40
              ), // Fit to screen width with padding
            }}
          >
            <div className="mb-4">
              <label className="block mb-2 font-semibold">
                Select Crop Option:
              </label>
              <select
                value={cropOption}
                onChange={(e) => setCropOption(e.target.value)}
                className="select select-bordered w-full"
              >
                <option value="squareLogo">Square Logo</option>
                <option value="rectangularLogo">Rectangular Logo</option>
              </select>
            </div>
            <div className="overflow-auto">
              {" "}
              {/* Ensuring the cropping area fits within the modal */}
              <AvatarEditor
                ref={setEditor}
                image={filePreview}
                width={getDimensions().width}
                height={getDimensions().height}
                border={50}
                color={[0, 0, 0, 0.8]} // Darker semi-transparent background around the cropping area
                scale={scale}
                rotate={0}
                style={{
                  maxWidth: "100%", // Ensuring the editor does not overflow
                  height: "auto", // Maintain aspect ratio
                  border: "2px dashed #fff", // Adding a distinct dashed border for better visibility
                }}
              />
            </div>
            <div className="mt-4 flex items-center">
              <label className="mr-2">Scale:</label>
              <input
                type="range"
                value={scale}
                min="1"
                max="3"
                step="0.1"
                onChange={(e) => setScale(parseFloat(e.target.value))}
              />
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleCancel} // Call handleCancel on clicking "Cancel"
                className="btn btn-secondary mr-2"
                type="button" // Add this line to prevent form submission
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="btn btn-primary"
                type="button"
              >
                Crop & Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomFileInput;
