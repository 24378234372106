import { Bar } from "react-chartjs-2";
import { useGetMonthlyPaymentsQuery } from "../../redux/dashboardApiSlice";
import FetchWrapper from "../../components/FetchWrapper";
import { useSelector } from "react-redux";
import { selectGym } from "../../redux/gymSlice";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const MonthlyPaymentsBarChart = () => {
  const { gymId } = useSelector(selectGym);
  const { data, error, isLoading } = useGetMonthlyPaymentsQuery(gymId);

  // Transform fetched data into the format required by Chart.js
  const paymentData = {
    labels:
      data?.map(
        (payment) => `${monthNames[payment.month - 1]} ${payment.year}`
      ) || [],
    datasets: [
      {
        label: "Monthly Payments",
        data: data?.map((payment) => payment.total) || [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)", // Red
          "rgba(54, 162, 235, 0.6)", // Blue
          "rgba(255, 206, 86, 0.6)", // Yellow
          "rgba(75, 192, 192, 0.6)", // Green
          "rgba(153, 102, 255, 0.6)", // Purple
          "rgba(255, 159, 64, 0.6)", // Orange
          "rgba(199, 199, 199, 0.6)", // Grey
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(199, 199, 199, 1)",
        ],
        borderWidth: 1,
        borderRadius: 12, // Soft rounded corners
        borderSkipped: "start", // Skip the start side; for vertical bars, this means the bottom
      },
    ],
  };

  const barOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let value = context.raw;
            return `${context.dataset.label}: NPR ${value.toLocaleString()} (${(value / 1000).toFixed(1)}k)`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `${(value / 1000).toFixed(1)}k`;
          },
        },
        suggestedMax: Math.max(...(paymentData.datasets[0].data || [0])) * 1.2,
      },
    },
  };

  return (
    <FetchWrapper isLoading={isLoading} isError={!!error} error={error}>
      <div className="bg-white rounded-lg shadow-md">
        <h2 className="px-6 pt-6 text-xl font-semibold mb-4">
          Monthly Payment Collections
        </h2>
        <div className="px-3 h-96">
          <Bar data={paymentData} options={barOptions} />
        </div>
      </div>
    </FetchWrapper>
  );
};

export default MonthlyPaymentsBarChart;
