import { Route } from "react-router-dom";
import GymSelector from "../ScreensProtected/gym/GymSelector";
import AddGymScreen from "../ScreensProtected/gym/AddGymScreen";
import RouteConfig from "../config/RouteConfig";
import Logout from "../ScreensPublic/auth/Logout";
import Layout from "../Layout";
import ErrorPage from "../ScreensPublic/ErrorPage";
import ProtectedHome from "../ScreensProtected/Home/ProtectedHome";
import GymScreen from "../ScreensProtected/gym/GymScreen";
import GymProfile from "../ScreensProtected/gym/GymProfile ";
import EditGymScreen from "../ScreensProtected/gym/EditGymScreen";
import GymPricingScreen from "../ScreensProtected/gym/GymPricingScreen";
import ClientListScreen from "../ScreensProtected/client/ClientListScreen";
import AddClientScreen from "../ScreensProtected/client/AddClientScreen";
import ClientProfile from "../ScreensProtected/client/ClientProfile";
import EditClientScreen from "../ScreensProtected/client/EditClientScreen";
import InvoiceScreen from "../ScreensProtected/client/invoice/InvoiceScreen";
import EditInvoice from "../ScreensProtected/client/invoice/EditInvoice";
import AddInvoice from "../ScreensProtected/client/invoice/AddInvoice";
import ClientAddPayments from "../ScreensProtected/client/ClientAddPayments";
import ClientEditPayments from "../ScreensProtected/client/ClientEditPayments";
import ScheduleScreen from "../ScreensProtected/Schedule/ScheduleScreen";
import DisplaySmsScreen from "../ScreensProtected/Sms/DisplaySmsScreen";
import AddSmsScreen from "../ScreensProtected/Sms/AddSmsScreen";
import ProfileScreen from "../ScreensProtected/profile/ProfileScreen";
import ChangePasswordScreen from "../ScreensProtected/profile/ChangePasswordScreen";
import EditProfileScreen from "../ScreensProtected/profile/EditProfileScreen";
import GymAdminRoute from "./GymAdminRoute";

const GymAdminRouteCollection = () => {
  return (
    <Route element={<GymAdminRoute />}>
      <Route index path={RouteConfig.GYM_SELECTOR} element={<GymSelector />} />
      <Route path="add" element={<AddGymScreen />} />

      {/* <Route path={RouteConfig.LOGOUT} element={<Logout />} /> */}
      <Route
        element={<Layout isProtected={true} />}
        errorElement={<ErrorPage />}
      >
        <Route path={RouteConfig.HOME} element={<ProtectedHome />} />

        <Route path={RouteConfig.GYM_CATEGORY}>
          <Route index element={<GymScreen />} />
          <Route path=":gymId" element={<GymProfile />} />
          <Route path="add" element={<AddGymScreen />} />
          <Route path="edit/:gymId" element={<EditGymScreen />} />
          <Route path="pricing/:gymId" element={<GymPricingScreen />} />
        </Route>

        <Route path={RouteConfig.CLIENTS}>
          <Route index element={<ClientListScreen />} />
          <Route path="add" element={<AddClientScreen />} />
          <Route path=":clientId" element={<ClientProfile />} />
          <Route path=":clientId/edit" element={<EditClientScreen />} />

          <Route
            path=":clientId/invoices/:invoiceId"
            element={<InvoiceScreen />}
          />
          <Route path=":clientId/add-invoices" element={<AddInvoice />} />
          <Route
            path=":clientId/invoices/:invoiceId/edit"
            element={<EditInvoice />}
          />
          <Route
            path=":clientId/invoices/:invoiceId/add-payment"
            element={<ClientAddPayments />}
          />
          <Route
            path=":clientId/invoices/:invoiceId/edit-payment/:paymentId"
            element={<ClientEditPayments />}
          />
        </Route>

        <Route path={RouteConfig.SCHEDULE}>
          <Route index element={<ScheduleScreen />} />
        </Route>

        <Route path={RouteConfig.SMS}>
          <Route index element={<DisplaySmsScreen />} />
          <Route path="add" element={<AddSmsScreen />} />
        </Route>

        <Route path={RouteConfig.PROFILE}>
          <Route index element={<ProfileScreen />} />
          <Route path="edit" element={<EditProfileScreen />} />
          <Route path="change-password" element={<ChangePasswordScreen />} />
        </Route>
      </Route>
    </Route>
  );
};

export default GymAdminRouteCollection;
