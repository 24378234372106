import React from "react";

const ErrorComponent = ({ error }) => {
  return (
    <div className="mt-5 flex flex-col items-center justify-center min-h-screen bg-red-100 text-red-700 p-4 rounded-lg z-50">
      <h2 className="text-2xl font-semibold">Error</h2>
      <p className="mt-2 text-lg">
        {error ? error.message || "An unexpected error occurred" : "An unexpected error occurred"}
      </p>
    </div>
  );
};

export default ErrorComponent;
