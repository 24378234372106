// alertSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: null,
  action: null, // Add action to the state
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlert: (state, action) => {
      state.message = action.payload.message;
      state.action = action.payload.action; // Set the action in the state
    },
    hideAlert: (state) => {
      state.message = null;
      state.action = null; // Reset the action
    },
  },
});

export const { showAlert, hideAlert } = alertSlice.actions;

export const selectAlert = (state) => state.alert;

export default alertSlice.reducer;
