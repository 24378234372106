import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import config from "../config/config.json";
import { logout } from "./userSlice";
import { showAlert } from "./alertSlice";
import { isTokenExpired } from "../utils/authUtils";

const baseQueryWithTokenCheck = async (args, api, extraOptions) => {
  const { getState, dispatch } = api;
  const token = getState().user.token;

  // Log the request details
  console.log("Request:", args);

  if (token && isTokenExpired(token)) {
    dispatch(
      showAlert({
        message: "Session has expired. Please log in again.",
        action: logout(), // Pass the logout action
      })
    );
    return {
      error: {
        status: 401,
        statusText: "Unauthorized",
        data: "Session expired",
      },
    };
  }

  const baseQuery = fetchBaseQuery({
    baseUrl: config.BASE_URL,
    prepareHeaders: (headers) => {
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });

  return baseQuery(args, api, extraOptions);
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithTokenCheck,
  tagTypes: [
    "Categories",
    "SMS",
    "SMSCount",
    "GymsSMSSetting",
    "Users",
    "Enums",
    "Payments",
    "Invoices",
    "Clients",
    "DashboardMetrics",
    "Gyms",
  ],
  endpoints: (builder) => ({}),
});
