const FormField = ({ field, value, onChange, error }) => (
  <div className="mb-4 flex flex-col">
    <div className="flex items-center mb-1">
      {field.icon}
      <label className="text-gray-700">{field.label}</label>
    </div>
    <input
      type={field.type}
      name={field.name}
      value={value}
      placeholder={field.placeholder || ""}
      onChange={onChange}
      className="w-full p-2 border rounded"
      required={field.required}
    />
    {error && <div className="text-red-500">{error}</div>}
  </div>
);

export default FormField;
