import {
  FaUserFriends,
  FaHeartbeat,
  FaClipboardList,
  FaBolt,
  FaGlobe,
  FaComments,
} from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";
import heroImage from "../../assets/team.jpg";
import { Link } from "react-router-dom";

const PublicHome = () => {
  return (
    <div>
      <HeroSection />
      <FeaturesSection />
      {/* <TestimonialsSection /> */}
      <CallToActionSection />
    </div>
  );
};

const HeroSection = () => {
  return (
    <div className="relative text-white py-20 hero-section">
      <div className="absolute inset-0 bg-gradient-to-r from-indigo-600 to-blue-500 opacity-90"></div>

      <div className="relative z-10 max-w-7xl mx-auto px-6 lg:px-8">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="flex flex-col items-center md:items-start md:w-6/12 lg:w-1/2 animate__animated animate__fadeInLeft">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-7xl font-extrabold mb-4 md:mb-6 text-center md:text-left">
              Join the Ultimate Fitness Community
            </h1>
            <p className="text-base sm:text-lg md:text-xl lg:text-2xl mb-4 md:mb-8 text-center md:text-left">
              Byayam.com brings gyms, trainers, and clients together in one
              dynamic network. Access top-notch CMS tools, personalized fitness
              plans, and a supportive community to reach your goals.
            </p>
            <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 md:py-3 px-4 md:px-6 rounded-full shadow-lg transition duration-300">
              Get Started
            </button>
            <div className="flex items-center justify-center mt-6 md:mt-8">
              <FiCheckCircle className="w-6 md:w-8 h-6 md:h-8 text-green-300 mr-2 md:mr-3" />
              <span className="text-base sm:text-lg md:text-xl lg:text-2xl font-semibold">
                Trusted by Fitness Enthusiasts Nationwide
              </span>
            </div>
          </div>
          <div className="mt-8 md:mt-0 md:w-6/12 lg:w-1/2 flex justify-center md:justify-end">
            <img
              src={heroImage}
              alt="Community Connection"
              className="w-full max-w-sm md:max-w-md lg:max-w-lg h-auto rounded-3xl shadow-2xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const features = [
  {
    title: "Gym Management System",
    description:
      "Empower your gym with our comprehensive CMS tailored for efficiency.",
    icon: FaClipboardList,
    available: true,
    link: "/gym-management", // Add a link property
  },
  {
    title: "Trainer-Client Matching",
    description:
      "We connect trainers to clients seeking personalized fitness guidance.",
    icon: FaUserFriends,
    available: false,
    link: "/trainer-matching", // Add a link property
  },
  {
    title: "Personalized Workout Plans",
    description: "Clients receive custom workout plans suited to their goals.",
    icon: FaBolt,
    available: false,
    link: "/workout-plans", // Add a link property
  },
  {
    title: "Customized Diet Plans",
    description: "Nutrition plans designed to complement your fitness regimen.",
    icon: FaHeartbeat,
    available: false,
    link: "/diet-plans", // Add a link property
  },
  {
    title: "Global Fitness Network",
    description: "Join a worldwide community of fitness enthusiasts.",
    icon: FaGlobe,
    available: false,
    link: "/fitness-network", // Add a link property
  },
  {
    title: "Community Support",
    description: "Engage with others, share progress, and stay motivated.",
    icon: FaUserFriends,
    available: false,
    link: "/community-support", // Add a link property
  },
];

const FeaturesSection = () => (
  <div className="py-20 bg-gray-100 features-section">
    <div className="container mx-auto px-6 lg:px-8">
      <h2 className="text-5xl font-bold mb-16 text-center">
        Discover Our Features
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12">
        {features.map((feature, index) => (
          <Link
            to={feature.link}
            key={index}
            className={`bg-white rounded-2xl shadow-xl p-8 flex flex-col items-center text-center transform hover:-translate-y-2 transition duration-300 feature-card ${
              !feature.available
                ? "opacity-60 cursor-not-allowed"
                : "cursor-pointer"
            }`}
            onClick={(e) => {
              if (!feature.available) {
                e.preventDefault();
              }
            }}
          >
            <div className="text-indigo-600 mb-6">
              <feature.icon className="w-16 h-16" />
            </div>
            <h3 className="text-2xl font-semibold mb-4">{feature.title}</h3>
            <p className="text-gray-700">{feature.description}</p>
            {!feature.available && (
              <span className="mt-4 inline-block bg-yellow-200 text-yellow-800 px-3 py-1 rounded-full text-sm font-semibold">
                Coming Soon
              </span>
            )}
          </Link>
        ))}
      </div>
    </div>
  </div>
);

const testimonials = [
  {
    quote:
      "Byayam.com transformed the way I manage my gym. The CMS is intuitive and has saved me countless hours.",
    name: "Sarah Johnson",
    role: "Gym Owner",
  },
  {
    quote:
      "As a trainer, connecting with clients has never been easier. The platform is fantastic!",
    name: "Mike Thompson",
    role: "Personal Trainer",
  },
  {
    quote:
      "The personalized workout and diet plans have helped me achieve my fitness goals. Highly recommend!",
    name: "Emily Davis",
    role: "Fitness Enthusiast",
  },
];

const TestimonialsSection = () => (
  <div className="py-20 bg-white testimonials-section">
    <div className="container mx-auto px-6 lg:px-8">
      <h2 className="text-5xl font-bold mb-16 text-center">
        What Our Members Say
      </h2>
      <div className="swiper-container">
        {/* Swiper slides can be implemented using Swiper.js or any carousel library */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-gray-50 rounded-2xl shadow-lg p-8 flex flex-col items-center text-center transform hover:scale-105 transition duration-300"
            >
              <FaComments className="w-12 h-12 text-indigo-600 mb-6" />
              <p className="text-xl italic mb-6">"{testimonial.quote}"</p>
              <h4 className="text-2xl font-semibold">{testimonial.name}</h4>
              <p className="text-indigo-600">{testimonial.role}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const CallToActionSection = () => (
  <div className="py-20 bg-gradient-to-r from-blue-500 to-indigo-600 text-white cta-section">
    <div className="container mx-auto px-6 lg:px-8 text-center">
      <h2 className="text-5xl font-bold mb-8">
        Ready to Elevate Your Fitness Journey?
      </h2>
      <p className="text-2xl mb-12">
        Join Byayam.com today and become part of a thriving fitness community.
      </p>
      <button className="bg-white text-indigo-600 font-bold py-3 px-8 rounded-full shadow-lg hover:bg-gray-100 transition duration-300">
        Sign Up Now
      </button>
    </div>
  </div>
);

export default PublicHome;
