import {
  FaRunning,
  FaDumbbell,
  FaSwimmer,
  FaHotTub,
  FaPrayingHands,
  FaBasketballBall,
  FaUserTie,
  FaSpa,
  FaShower,
  FaWifi,
  FaCoffee,
  FaFistRaised,
} from "react-icons/fa";

export const facilitiesList = [
  {
    name: "Cardio and Aerobics",
    icon: <FaRunning style={{ color: "blue" }} />,
  },
  {
    name: "Weightlifting and Powerlifting",
    icon: <FaDumbbell style={{ color: "red" }} />,
  },
  {
    name: "CrossFit and Functional Training",
    icon: <FaRunning style={{ color: "blue" }} />,
  },
  {
    name: "Swimming Pool",
    icon: <FaSwimmer style={{ color: "aqua" }} />,
  },
  {
    name: "Sauna and Steam Room",
    icon: <FaHotTub style={{ color: "orange" }} />,
  },
  {
    name: "Yoga and Pilates Studio",
    icon: <FaPrayingHands style={{ color: "purple" }} />,
  },
  {
    name: "Boxing and Martial Arts",
    icon: <FaFistRaised style={{ color: "darkorange" }} />,
  },
  {
    name: "Indoor Sports Courts (Basketball, Tennis, Squash)",
    icon: <FaBasketballBall style={{ color: "orange" }} />,
  },
  {
    name: "Personal Training Services",
    icon: <FaUserTie style={{ color: "blue" }} />,
  },
  {
    name: "Spa and Massage Therapy",
    icon: <FaSpa style={{ color: "pink" }} />,
  },
  {
    name: "Locker Rooms with Showers",
    icon: <FaShower style={{ color: "lightblue" }} />,
  },
  {
    name: "Wi-Fi Access",
    icon: <FaWifi style={{ color: "grey" }} />,
  },
  {
    name: "Cafe and Refreshments",
    icon: <FaCoffee style={{ color: "brown" }} />,
  },
];
