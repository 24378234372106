import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetUsersQuery } from "../../redux/userApiSlice"; // Make sure to replace with the correct path
import FetchWrapper from "../../components/FetchWrapper";
import useMediaQuery from "../../hooks/useMediaQuery";
import Table from "../../components/Table";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import ConfirmDialog from "../../components/ConfirmDialog";
import useSnackbar from "../../hooks/useSnackbar";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const UsersListScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [order, setOrder] = useState(query.get("sortOrder") || "asc");
  const [orderBy, setOrderBy] = useState(query.get("sortBy") || "name");
  const [page, setPage] = useState(parseInt(query.get("page") || 0, 10));
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(query.get("rowsPerPage") || 25, 10)
  );
  const [searchParams, setSearchParams] = useState({
    name: query.get("name") || "",
    phone: query.get("phone") || "",
  });

  const {
    data: usersData,
    isLoading,
    isError,
    error,
  } = useGetUsersQuery({
    name: searchParams.name,
    phone: searchParams.phone,
    pageNo: page,
    pageSize: rowsPerPage,
    sortBy: orderBy,
    sortOrder: order,
  });

  const handleRequestSort = (property) => {
    const columnMapping = {
      Name: "name",
      Sms: "totalSms",
      Role: "role",
      ExpiryDate: "paymentExpiryDate",
    };

    const isAsc = orderBy === columnMapping[property] && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(columnMapping[property]);
    updateURL({ sortOrder: newOrder, sortBy: columnMapping[property] });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    updateURL({ page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page when rows per page change
    updateURL({ rowsPerPage: newRowsPerPage, page: 0 });
  };

  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    setSearchParams((prev) => ({
      ...prev,
      [name]: value,
    }));
    updateURL({ [name]: value, page: 0 });
  };

  const updateURL = (params) => {
    const searchParams = new URLSearchParams(location.search);
    Object.keys(params).forEach((key) => {
      if (params[key] !== undefined) {
        searchParams.set(key, params[key]);
      }
    });
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  return (
    <div className=" mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Users</h1>
      </div>

      <div className="mb-4">
        <p className="text-lg font-semibold">Search Users By:</p>
      </div>

      <SearchForm searchParams={searchParams} onChange={handleSearchChange} />

      <FetchWrapper
        isError={isError}
        error={error}
        isLoading={isLoading}
        isEmptyList={usersData && usersData.content.length === 0}
      >
        {usersData && (
          <>
            {isMobile ? (
              <UserCardList users={usersData.content} navigate={navigate} />
            ) : (
              <UserTable
                users={usersData.content}
                orderBy={orderBy}
                order={order}
                onSort={handleRequestSort}
                navigate={navigate}
              />
            )}
            <PaginationControls
              page={page}
              rowsPerPage={rowsPerPage}
              totalElements={usersData.paginationResponse.totalElements}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </FetchWrapper>
    </div>
  );
};

const SearchForm = ({ searchParams, onChange }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
    {["name", "phone"].map((field) => (
      <input
        key={field}
        type="text"
        name={field}
        value={searchParams[field]}
        onChange={onChange}
        placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
        className="w-full p-2 border rounded"
      />
    ))}
  </div>
);

const RenderActions = (user) => {
  const navigate = useNavigate();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const handleDeleteUser = async () => {
    try {
      // Implement deleteUser API call
      // await deleteUser(user.id).unwrap();
      showSnackbar("Todo:Work pending", "error");
      // showSnackbar("User deleted successfully!", "success");
    } catch (error) {
      showSnackbar("Failed to delete user.");
      console.error("Failed to delete user: ", error);
    } finally {
      setDialogOpen(false);
    }
  };

  return (
    <>
      <div className="flex flex-row space-y-0 space-x-2">
        {/* Edit Button */}
        <button
          className="btn btn-warning text-white p-2 rounded flex items-center justify-center"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`${user.id}/edit`);
          }}
        >
          <FaEdit />
        </button>

        {/* Delete Button */}
        <button
          className="btn bg-red-500 text-white p-2 rounded flex items-center justify-center"
          onClick={(e) => {
            e.stopPropagation();
            setDialogOpen(true); // Open the confirmation dialog
          }}
        >
          <FaTrashAlt />
        </button>
      </div>
      <SnackbarComponent />
      <ConfirmDialog
        isOpen={isDialogOpen}
        title="Delete User"
        message="Are you sure you want to delete this user? This action cannot be undone."
        onConfirm={(e) => {
          e.stopPropagation(); // Prevent event bubbling
          handleDeleteUser();
        }}
        onCancel={(e) => {
          e.stopPropagation(); // Prevent event bubbling
          setDialogOpen(false);
        }}
      />
    </>
  );
};

const UserTable = ({ users, orderBy, order, onSort, navigate }) => {
  const handleRowClick = (user) => {
    navigate(`${user.id}`);
  };

  const userRows = users.map((user) => ({
    id: user.id,
    name: user.name,
    phone: user.phone || "N/A",
    sms: user.totalSms || "N/A",
    role: user.role || "N/A",
    expiryDate: user.paymentExpiryDate || "N/A",
    verified: user.verified ? "Verified" : "Unverified",
  }));

  return (
    <Table
      headers={{
        values: [
          "id",
          "Name",
          "Phone",
          "Sms",
          "Role",
          "ExpiryDate",
          "Verified",
        ],
        sortable: ["Name", "Sms", "Role", "ExpiryDate"],
      }}
      rows={userRows}
      onSort={onSort}
      orderBy={orderBy}
      order={order}
      onRowClick={handleRowClick}
      actions={RenderActions}
    />
  );
};

const UserCardList = ({ users, navigate }) => {
  return (
    <div className="grid grid-cols-1 gap-4">
      {users.map((user) => (
        <div
          key={user.id}
          className="border rounded p-4 bg-white shadow-sm"
          onClick={() => navigate(`${user.id}`)}
        >
          <div className="flex items-center mb-2">
            <div>
              <h2 className="text-lg font-semibold">{user.name}</h2>
              <p>{user.phone || "N/A"}</p>
              <p>Role: {user.role || "N/A"}</p>
              <p>Status: {user.verified ? "Verified" : "Unverified"}</p>
            </div>
          </div>
          <div className="mt-4">{RenderActions(user)}</div>
        </div>
      ))}
    </div>
  );
};

const PaginationControls = ({
  page,
  rowsPerPage,
  totalElements,
  onPageChange,
  onRowsPerPageChange,
}) => (
  <div className="flex flex-col md:flex-row justify-between items-center py-2 space-y-2 md:space-y-0">
    <div className="flex items-center">
      <span>Rows per page:</span>
      <select
        value={rowsPerPage}
        onChange={onRowsPerPageChange}
        className="ml-2 p-1 border rounded"
      >
        {[5, 10, 25, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </select>
    </div>
    <div className="flex items-center space-x-2">
      <span>
        Page: {page + 1} of {Math.ceil(totalElements / rowsPerPage)}
      </span>
      <button
        onClick={() => onPageChange(page - 1)}
        disabled={page === 0}
        className="p-1 border rounded"
      >
        Previous
      </button>
      <button
        onClick={() => onPageChange(page + 1)}
        disabled={page >= Math.ceil(totalElements / rowsPerPage) - 1}
        className="p-1 border rounded"
      >
        Next
      </button>
    </div>
  </div>
);

export default UsersListScreen;
