import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { logout, selectUser } from "../redux/userSlice";
import { FaHourglassHalf } from "react-icons/fa";
import RouteConfig from "../config/RouteConfig";

const GymAdminRoute = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  // Redirect to login if not authenticated
  if (!user.isAuthenticated) {
    return <Navigate to={RouteConfig.LOGIN} replace />;
  }

  // Redirect to superadmin if  authenticated
  if (user.superAdmin && user.adminPanel) {
    return <Navigate to={RouteConfig.ADMIN_HOME} replace />;
  }

  // Show a message if authenticated but not verified
  if (!user.verified) {
    return <VerificationCard onLogout={handleLogout} />;
  }

  return <Outlet />;
};

const VerificationCard = ({ onLogout }) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600">
      <div className="card w-full max-w-md shadow-2xl bg-base-100 text-center">
        <div className="card-body">
          <FaHourglassHalf className="text-4xl text-warning mx-auto" />
          <h1 className="text-lg font-bold mt-4">Please Wait...</h1>
          <p className="text-gray-600 mt-4">
            Byayam.com Team will verify you shortly.
          </p>
          <p className="text-gray-600 mt-4">Thank you for choosing us.</p>
          <button onClick={onLogout} className="btn btn-error mt-6">
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default GymAdminRoute;
