// components/Table.jsx
import React from "react";

// TableHeader Component
const TableHeader = ({ headers = {}, onSort, orderBy, order, actions }) => {
  const { values = [], sortable = [] } = headers;

  return (
    <thead>
      <tr>
        {values.map((header) => {
          if (header === "id") return null;

          return (
            <th
              key={header}
              className={`bg-gray-500 text-white p-2 ${sortable.includes(header) ? "cursor-pointer" : ""}`}
              onClick={() => {
                if (sortable.includes(header)) {
                  onSort(header);
                }
              }}
            >
              <div className="flex items-center">
                {header.charAt(0).toUpperCase() + header.slice(1)}
                {sortable.includes(header) && (
                  <span>
                    {orderBy === header
                      ? order === "asc"
                        ? " ▲"
                        : " ▼"
                      : " ⇵"}
                  </span>
                )}
              </div>
            </th>
          );
        })}
        {actions && <th className="p-2 bg-gray-500 text-white">Actions</th>}
      </tr>
    </thead>
  );
};

// TableRows Component
const TableRows = ({ rows = [], onRowClick, actions }) => (
  <tbody>
    {rows.map((row, index) => {
      return (
        <tr
          key={row.id}
          className={`border-b ${index % 2 === 0 ? "bg-gray-50" : ""}`}
          style={{ cursor: onRowClick ? "pointer" : "default" }}
          onClick={() => onRowClick && onRowClick(row)}
        >
          {Object.keys(row).map(
            (key) =>
              key !== "id" && (
                <td key={key} className="p-2">
                  {row[key]}
                </td>
              )
          )}
          {actions && (
            <td className="p-2" onClick={(e) => e.stopPropagation()}>
              <div className="flex justify-center items-center h-full">
                {actions(row)}
              </div>
            </td>
          )}
        </tr>
      );
    })}
  </tbody>
);


// Table Component
const Table = ({
  headers,
  rows,
  onSort,
  orderBy,
  order,
  onRowClick,
  actions,
}) => (
  <div className="overflow-x-auto">
    <table className="table w-full bg-white border rounded">
      <TableHeader
        headers={headers}
        onSort={onSort}
        orderBy={orderBy}
        order={order}
        actions={actions}
      />
      <TableRows rows={rows} onRowClick={onRowClick} actions={actions} />
    </table>
  </div>
);

export default Table;
