import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import PublicRouteCollection from "./PublicRouteCollection";
import GymAdminRouteCollection from "./GymAdminRouteCollection";
import SuperAdminRouteCollection from "./SuperAdminRouteCollection";
import RouteConfig from "../config/RouteConfig";
import Logout from "../ScreensPublic/auth/Logout";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {PublicRouteCollection()}

      {/* Protected Routes */}
      {GymAdminRouteCollection()}

      {/* Admin Routes */}
      {SuperAdminRouteCollection()}

      <Route path={RouteConfig.LOGOUT} element={<Logout />} />
    </Route>
  )
);

export default router;
