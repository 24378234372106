import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleAdminPanel, selectUser } from "../redux/userSlice";

const ToggleAdminPanelButton = () => {
  const dispatch = useDispatch();
  const { adminPanel, superAdmin } = useSelector(selectUser);

  const handleToggle = () => {
    dispatch(toggleAdminPanel());
  };

  // Conditionally render the button only if superAdmin is true
  if (!superAdmin) {
    return null; // Hide the button if superAdmin is false
  }

  return (
    <div
      onClick={handleToggle}
      className={`ml-2 relative inline-flex items-center h-6 rounded-full w-11 cursor-pointer ${
        adminPanel ? "bg-blue-500" : "bg-gray-300"
      }`}
    >
      <span
        className={`transform transition ease-in-out duration-200 inline-block w-4 h-4 rounded-full bg-white ${
          adminPanel ? "translate-x-6" : "translate-x-1"
        }`}
      />
    </div>
  );
};

export default ToggleAdminPanelButton;
