const FormCardWrapper = ({ title, children }) => (
  <div className="bg-white shadow-md rounded-lg mb-4 col-span-1 md:col-span-2">
    <div className="bg-gray-500 text-white p-4 rounded-t-lg">
      <h2 className="text-xl font-semibold">{title}</h2>
    </div>
    <div className="p-4 bg-gray-100 rounded-b-lg">{children}</div>
  </div>
);

export default FormCardWrapper;
