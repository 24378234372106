import { apiSlice } from "./apiSlice";

export const clientApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    searchClientsWithPagination: builder.query({
      query: ({
        gymId,
        name,
        phone,
        pageNo = 0,
        pageSize = 25,
        sortBy = "name",
        sortOrder = "asc",
      }) => {
        const params = { gymId, pageNo, pageSize, sortBy, sortOrder };
        if (name) params.name = name;
        if (phone) params.phone = phone;
        if (gymId) params.gymId = gymId;

        return {
          url: `/api/clients/gyms/${gymId}`,
          params,
        };
      },
      providesTags: ["Clients", "Invoices", ],
    }),

    getActiveExpiredClientsByGymId: builder.query({
      // Changed
      query: ({ gymId, page = 0, size = 10 }) =>
        `/api/expired-clients/gyms/${gymId}?page=${page}&size=${size}`,
      providesTags: ["Clients", "Invoices"],
    }),

    getClientById: builder.query({
      query: (id) => `/api/clients/${id}`,
      providesTags: ["Clients", "Invoices"],
    }),

    createClient: builder.mutation({
      query: ({ clientDto, file }) => {
        const formData = new FormData();
        Object.keys(clientDto).forEach((key) => {
          formData.append(key, clientDto[key]);
        });
        if (file) {
          formData.append("file", file);
        }
        return {
          url: `/api/clients`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Clients"],
    }),

    updateClient: builder.mutation({
      query: ({ id, clientDto, file }) => {
        const formData = new FormData();
        Object.keys(clientDto).forEach((key) => {
          formData.append(key, clientDto[key]);
        });
        if (file) {
          formData.append("file", file);
        }
        return {
          url: `/api/clients/${id}`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: ["Clients"],
    }),

    deleteClient: builder.mutation({
      query: (id) => ({
        url: `/api/clients/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Clients"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useSearchClientsWithPaginationQuery,
  useGetActiveExpiredClientsByGymIdQuery,
  useGetClientByIdQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
} = clientApiSlice;
