import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
  FaTiktok,
} from "react-icons/fa";

const socialMediaIcons = {
  facebook: <FaFacebookF className="text-blue-600" />,
  instagram: <FaInstagram className="text-pink-600" />,
  tikTok: <FaTiktok className="text-black" />,
  youtube: <FaYoutube className="text-red-600" />,
  twitter: <FaTwitter className="text-blue-400" />,
  linkedIn: <FaLinkedinIn className="text-blue-700" />,
};

export default socialMediaIcons;
