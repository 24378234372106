import { useState } from "react";

const useSnackbar = () => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const showSnackbar = (message, severity = "error") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setIsSnackbarOpen(true);

    setTimeout(() => {
      setIsSnackbarOpen(false);
    }, 5000); // Auto hide after 5 seconds
  };

  const SnackbarComponent = () => (
    isSnackbarOpen && (
      <div
        className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 p-4 rounded shadow-lg text-white z-50 ${
          snackbarSeverity === "success"
            ? "bg-green-500"
            : snackbarSeverity === "warning"
            ? "bg-yellow-500"
            : "bg-red-500"
        }`}
      >
        {snackbarMessage}
      </div>
    )
  );

  return { showSnackbar, SnackbarComponent };
};

export default useSnackbar;
