import React from "react";

const FullScreenLoading = () => {
  return (
    <div className="flex items-center justify-center bg-white h-4/5 w-full fixed top-0 left-0 ">
      <div className="flex items-center space-x-2">
        <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce"></div>
        <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce200"></div>
        <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce400"></div>
      </div>
    </div>
  );
};

export default FullScreenLoading;
