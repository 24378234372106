import { apiSlice } from "./apiSlice";

export const enumApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserRoles: builder.query({
      query: () => ({
        url: "/api/enums/user-role",
      }),
      providesTags: ["Enums"],
    }),
    getDistricts: builder.query({
      query: () => ({
        url: "/api/enums/district",
      }),
      providesTags: ["Enums"],
    }),
    getPaymentTypes: builder.query({
      query: () => ({
        url: "/api/enums/payment-type",
      }),
      providesTags: ["Enums"],
    }),
    getPaymentStatus: builder.query({
      query: () => ({
        url: "/api/enums/payment-status",
      }),
      providesTags: ["Enums"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetUserRolesQuery,
  useGetDistrictsQuery,
  useGetPaymentStatusQuery,
  useGetPaymentTypesQuery
} = enumApiSlice;
