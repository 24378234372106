import { Route } from "react-router-dom";
import SuperAdminRoute from "./SuperAdminRoute";
import RouteConfig from "../config/RouteConfig";
import AdminHome from "../ScreensAdmin/home/AdminHome";
import AdminLayout from "../layout/AdminLayout";
import ErrorPage from "../ScreensPublic/ErrorPage";
import ManageGymsScreen from "../ScreensAdmin/gyms/ManageGymsScreen";
import ManageUsersScreen from "../ScreensAdmin/users/ManageUsersScreen";
import ManageUserProfile from "../ScreensAdmin/users/ManageUserProfile";
import ManageEditUserProfile from "../ScreensAdmin/users/ManageEditUserProfile";

const SuperAdminRouteCollection = () => {
  return (
    <Route element={<SuperAdminRoute />}>
      <Route element={<AdminLayout />} errorElement={<ErrorPage />}>
        <Route path={RouteConfig.ADMIN_HOME} element={<AdminHome />} />
        <Route path={RouteConfig.MANAGE_Gyms} element={<ManageGymsScreen />} />

        <Route path={RouteConfig.Manage_Users}>
          <Route index element={<ManageUsersScreen />} />
          <Route path=":userId" element={<ManageUserProfile />} />
          <Route path=":userId/edit" element={<ManageEditUserProfile />} />
        </Route>
      </Route>
      {/* <Route path={RouteConfig.LOGOUT} element={<Logout />} /> */}
    </Route>
  );
};

export default SuperAdminRouteCollection;
