import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../redux/userSlice";
import useSnackbar from "../../hooks/useSnackbar";
import { useAuthenticateStoreMutation } from "../../redux/authApiSlice";
import { FaUserCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import RouteConfig from "../../config/RouteConfig";

const StoreLogin = () => {
  const [formData, setFormData] = useState({
    phone: "",
    password: "",
    showPassword: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authenticateStore, { isLoading }] = useAuthenticateStoreMutation();
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const togglePasswordVisibility = () => {
    setFormData((prev) => ({ ...prev, showPassword: !prev.showPassword }));
  };

  const handleSignUp = () => {
    navigate(RouteConfig.SIGNUP);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { phone, password } = formData;

    if (!phone.trim() || !password.trim()) {
      showSnackbar("Phone and password are required", "error");
      return;
    }

    try {
      const credentials = { phone, password };
      const user = await authenticateStore(credentials).unwrap();
      dispatch(login(user));
      showSnackbar("Successfully authenticated!", "success");
      navigate(RouteConfig.DASHBOARD);
    } catch (err) {
      showSnackbar(err.data?.message || "Unknown error", "error");
      console.error("Failed to authenticate: ", err);
    }
  };

  return (
    <div className=" flex items-center justify-center bg-gradient-to-r from-blue-50 to-gray-50">
      <SnackbarComponent />
      <div className="my-5 w-full max-w-md bg-white shadow-lg rounded-3xl overflow-hidden p-8">
        <div className="flex flex-col items-center">
          <FaUserCircle className="text-6xl text-indigo-600 mb-4" />
          <h2 className="text-3xl font-bold text-gray-800 mb-4">
            Welcome Back!
          </h2>
          <p className="text-gray-600 text-center mb-8">
            Log in to your account to manage your gym efficiently.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="relative">
            <label htmlFor="phone" className="sr-only">
              Phone
            </label>
            <input
              id="phone"
              name="phone"
              type="tel"
              autoComplete="tel"
              required
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </div>
          <div className="relative">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <input
              id="password"
              name="password"
              type={formData.showPassword ? "text" : "password"}
              autoComplete="current-password"
              required
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
            />
            <button
              type="button"
              className="absolute top-4 right-4 text-gray-500"
              onClick={togglePasswordVisibility}
            >
              {formData.showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="flex justify-between items-center">
            <p
              href="#"
              className="text-sm text-indigo-600 hover:underline"
            >
              Forgot Password?
            </p>
          </div>
          <button
            type="submit"
            className={`w-full py-3 mt-4 bg-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 transition duration-300 ${
              isLoading ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={isLoading}
          >
            {isLoading ? "Logging in..." : "Login"}
          </button>
          <button
            type="button"
            className="w-full py-3 mt-4 border border-indigo-600 text-indigo-600 font-semibold rounded-lg shadow-md hover:bg-indigo-50 transition duration-300"
            onClick={handleSignUp}
          >
            Sign Up
          </button>
        </form>
        <div className="mt-8 text-center">
          <p className="text-gray-600">
            Need help?{" "}
            <a href="/contact-us" className="text-indigo-600 hover:underline">
              Contact Support
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default StoreLogin;
