import React, { useState } from "react";
import { FaLock } from "react-icons/fa";
import useSnackbar from "../../hooks/useSnackbar";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/userSlice";
import { useChangePasswordMutation } from "../../redux/userApiSlice";

const ChangePasswordScreen = () => {
  const { userId } = useSelector(selectUser);
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordLengthValid, setPasswordLengthValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Check if passwords match when newPassword or confirmPassword changes
    if (name === "newPassword" || name === "confirmPassword") {
      const newMatch = name === "newPassword" ? value === passwords.confirmPassword : passwords.newPassword === value;
      setPasswordMatch(newMatch);

      // Validate the new password length
      if (name === "newPassword") {
        setPasswordLengthValid(value.length >= 8);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { oldPassword, newPassword, confirmPassword } = passwords;

    if (!oldPassword.trim() || !newPassword.trim() || !confirmPassword.trim()) {
      showSnackbar("All fields are required", "error");
      return;
    }

    if (newPassword.length < 8) {
      showSnackbar("New password must be at least 8 characters long", "error");
      return;
    }

    if (newPassword !== confirmPassword) {
      showSnackbar("New passwords do not match", "error");
      return;
    }

    try {
      await changePassword({ userId, oldPassword, newPassword }).unwrap();
      showSnackbar("Password successfully changed!", "success");
      setPasswords({ oldPassword: "", newPassword: "", confirmPassword: "" });
    } catch (err) {
      const errorMessage =
        err.data?.message ||
        "Failed to change password due to an unknown error.";
      showSnackbar(errorMessage, "error");
      console.error("Failed to change password: ", errorMessage);
    }
  };

  return (
    <div
      style={{ minHeight: `calc(100vh - var(--navbar-height))` }}
      className="flex items-center justify-center bg-gray-100"
    >
      <SnackbarComponent />
      <div className="max-w-md p-8 space-y-8 bg-white shadow-lg rounded-lg">
        <div className="flex flex-col items-center">
          <FaLock className="text-4xl text-gray-800 mb-4" />
          <h2 className="text-4xl font-bold text-center text-gray-800">
            Change Password
          </h2>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="oldPassword"
              className="block text-sm font-medium text-gray-700"
            >
              Old Password
            </label>
            <input
              id="oldPassword"
              name="oldPassword"
              type="password"
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={passwords.oldPassword}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label
              htmlFor="newPassword"
              className="block text-sm font-medium text-gray-700"
            >
              New Password
            </label>
            <input
              id="newPassword"
              name="newPassword"
              type={showPassword ? "text" : "password"}
              required
              className={`mt-1 block w-full px-3 py-2 border ${passwordLengthValid ? "border-gray-300" : "border-red-500"} rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              value={passwords.newPassword}
              onChange={handleInputChange}
            />
            {!passwordLengthValid && (
              <p className="text-red-500 text-xs mt-1">Password must be at least 8 characters long.</p>
            )}
          </div>
          <div>
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-gray-700"
            >
              Confirm New Password
            </label>
            <input
              id="confirmPassword"
              name="confirmPassword"
              type={showPassword ? "text" : "password"}
              required
              className={`mt-1 block w-full px-3 py-2 border ${passwordMatch ? "border-gray-300" : "border-red-500"} rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              value={passwords.confirmPassword}
              onChange={handleInputChange}
            />
            {!passwordMatch && (
              <p className="text-red-500 text-xs mt-1">Passwords do not match.</p>
            )}
          </div>
          <div className="flex justify-between items-center">
            <button
              type="button"
              className="text-sm text-gray-600 hover:text-gray-500"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "Hide Passwords" : "Show Passwords"}
            </button>
          </div>
          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={isLoading || !passwordMatch || !passwordLengthValid}
            >
              {isLoading ? "Changing..." : "Change Password"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordScreen;
