import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./userSlice";
import gymReducer from "./gymSlice";
import alertReducer from "./alertSlice";

import { apiSlice } from "./apiSlice";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["user", "gym"],
};

const rootReducer = combineReducers({
  user: userReducer,
  gym: gymReducer,
  alert: alertReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export default persistReducer(persistConfig, rootReducer);
