import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AlertComponent from "./components/AlertComponent";
import ProtectedNavBar from "./layout/ProtectedNavBar";
import PublicNavBar from "./layout/PublicNavBar";
import Footer from "./layout/Footer";
import { useSelector } from "react-redux";
import { selectGym } from "./redux/gymSlice";
import RouteConfig from "./config/RouteConfig";

const Layout = ({ isProtected }) => {
  const navigate = useNavigate();
  const { gymId } = useSelector(selectGym);

  useEffect(() => {
    if (isProtected && !gymId) {
      navigate(RouteConfig.GYM_SELECTOR, { replace: true });
    }
  }, [gymId, isProtected, navigate]);

  return (
    <>
      {isProtected ? <ProtectedNavBar /> : <PublicNavBar />}
      <div>
        <AlertComponent />
        <Outlet />
      </div>
      {!isProtected ? <Footer /> : null}
    </>
  );
};

export default Layout;
