import React from "react";
import { useCreateGymMutation } from "../../redux/gymApiSlice";
import GymForm from "./GymForm"; // Import the form component
import RouteConfig from "../../config/RouteConfig";
import { useNavigate } from "react-router-dom";

const AddGymScreen = () => {
  const [createGym, { isLoading }] = useCreateGymMutation();
  const navigate = useNavigate();

  const initialData = {
    gymName: null,
    gymLogoImageUrl: null,
    address: null,
    branch: null,
    contactNumber: null,
    emailAddress: null,
    websiteURL: null,
    openingHours: null,
    facilities: null,
    latitude: null,
    longitude: null,
    socialMediaLinks: [],
  };

  const handleSubmit = async ({ gymData, wifiFile, logoFile }) => {
    await createGym({ gymData, wifiFile, logoFile }).unwrap();
    navigate(RouteConfig.HOME, { replace: true });
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Add New Gym</h1>
      <GymForm
        initialData={initialData}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AddGymScreen;
