import React from "react";
import { useRouteError } from "react-router-dom";

const ErrorPage = ({ minHeight, message }) => {
  const error = useRouteError();

  return (
    <div
      className="flex flex-col items-center justify-center"
      style={{ minHeight: minHeight || "100vh", textAlign: "center" }}
    >
      <h1 className="text-3xl font-bold mb-4">Oops!</h1>
      <p className="text-lg mb-4">
        Sorry, an unexpected error has occurred.
      </p>
      <p className="text-sm">
        <i>{message || error?.statusText || error?.message}</i>
      </p>
    </div>
  );
};

export default ErrorPage;
