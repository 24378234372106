import React, { useEffect, useState } from "react";
import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaGlobe,
  FaClock,
  FaBuilding,
  FaClipboardList,
  FaStar,
  FaStore,
} from "react-icons/fa";
import useSnackbar from "../../hooks/useSnackbar";
import CustomFileInput from "../../components/CustomFileInput";
import { facilitiesList } from "./facilitiesList";
import socialMediaIcons from "../../components/SocialMediaIcons";
import FormCardWrapper from "../../components/FormCardWrapper";
import FormField from "../../components/FormField";

const defaultIcon = <FaStar className="text-gray-500" />; // Default icon


const GymForm = ({ initialData, onSubmit, isLoading }) => {
  const [gymData, setGymData] = useState(initialData || {});
  const [wifiFile, setWifiFile] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [errors, setErrors] = useState({});
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const [loadingLocation, setLoadingLocation] = useState(false); // New state for loading

  useEffect(() => {
    setGymData(initialData || {});
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGymData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileLogoChange = (selectedFile) => {
    setLogoFile(selectedFile);
  };

  const handleFileWifiChange = (selectedFile) => {
    setWifiFile(selectedFile);
  };

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      setLoadingLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setGymData((prev) => ({
            ...prev,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }));
          showSnackbar("Location retrieved successfully!", "success");
          setLoadingLocation(false);
        },
        (error) => {
          handleGeolocationError(error);
          setLoadingLocation(false);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      showSnackbar("Geolocation is not supported by this browser.", "error");
      setLoadingLocation(false);
    }
  };

  const handleGeolocationError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        showSnackbar("Permission to access location was denied.", "error");
        break;
      case error.POSITION_UNAVAILABLE:
        showSnackbar("Location information is unavailable.", "error");
        break;
      case error.TIMEOUT:
        showSnackbar("The request to get your location timed out.", "error");
        break;
      default:
        showSnackbar(
          "An unknown error occurred while fetching location.",
          "error"
        );
        break;
    }
  };

  const validate = () => {
    let tempErrors = {};
    if (!gymData.gymName) tempErrors.gymName = "Gym name is required.";
    if (!gymData.contactNumber || gymData.contactNumber.length === 0)
      tempErrors.contactNumber = "At least one contact number is required.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      await onSubmit({ gymData, wifiFile, logoFile });
      setWifiFile(null);
      setLogoFile(null);
      showSnackbar("Gym saved successfully!", "success");
    } catch (err) {
      console.error("Failed to save gym:", err);
      showSnackbar(
        "Failed to save gym: " + (err.data?.message || "Unknown error"),
        "error"
      );
    }
  };

  const handleFacilityChange = (e) => {
    const { name, checked, value } = e.target;
    let updatedFacilities = [...(gymData.facilities || [])];

    if (name === "manual") {
      const newFacilities = value
        .trim()
        .split(",")
        .map((facility) => facility.trim())
        .filter(Boolean);

      updatedFacilities = [
        ...updatedFacilities,
        ...newFacilities.filter(
          (facility) => !updatedFacilities.includes(facility)
        ),
      ];
    } else {
      updatedFacilities = checked
        ? [...updatedFacilities, name]
        : updatedFacilities.filter((facility) => facility !== name);
    }

    setGymData((prev) => ({
      ...prev,
      facilities: updatedFacilities,
    }));
  };

  const handleSocialMediaChange = (updatedLinks) => {
    setGymData((prevData) => ({
      ...prevData,
      socialMediaLinks: updatedLinks,
    }));
  };

  return (
    <div className="container mx-auto">
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-1 md:grid-cols-2 gap-4"
      >
        <BasicInfo
          inputFields={inputFields}
          gymData={gymData}
          handleChange={handleChange}
          errors={errors}
        />

        <FacilitiesField gymData={gymData} onChange={handleFacilityChange} />

        <LocationField
          gymData={gymData}
          onChange={handleChange}
          onGetLocation={handleGetLocation}
          loadingLocation={loadingLocation}
        />

        <SocialMediaLinks
          socialMediaLinks={gymData.socialMediaLinks}
          onChange={handleSocialMediaChange}
        />

        <ImageUploadSection
          handleFileLogoChange={handleFileLogoChange}
          logoFile={logoFile}
          handleFileWifiChange={handleFileWifiChange}
          wifiFile={wifiFile}
        />

        <div className="col-span-1 md:col-span-2 flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 text-white btn max-w-lg w-full"
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save Gym"}
          </button>
        </div>
      </form>
      <SnackbarComponent />
    </div>
  );
};

export default GymForm;

const BasicInfo = ({ inputFields, gymData, handleChange, errors }) => (
  <FormCardWrapper title="Basic Info">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {inputFields.map((field, index) => (
        <FormField
          key={index}
          field={field}
          value={gymData[field.name] ?? ""}
          onChange={handleChange}
          error={errors[field.name]}
        />
      ))}
    </div>
  </FormCardWrapper>
);

const ImageUploadSection = ({
  handleFileLogoChange,
  logoFile,
  handleFileWifiChange,
  wifiFile,
}) => (
  <FormCardWrapper title="Images">
    <div className="flex justify-evenly">
      <CustomFileInput
        name="Logo Image"
        onChange={handleFileLogoChange}
        reset={!logoFile}
      />
      <CustomFileInput
        name="WiFi Scanner Image"
        onChange={handleFileWifiChange}
        reset={!wifiFile}
      />
    </div>
  </FormCardWrapper>
);

const LocationField = ({
  gymData,
  onChange,
  onGetLocation,
  loadingLocation,
}) => (
  <FormCardWrapper title="Location">
    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 md:items-end">
      <div className="flex-1">
        <label className="text-gray-700 flex items-center">
          <FaMapMarkerAlt className="text-yellow-500 mr-2" />
          Latitude
        </label>
        <input
          type="number"
          name="latitude"
          value={gymData.latitude ?? ""}
          onChange={onChange}
          className="w-full p-2 border rounded"
          step="any"
        />
      </div>
      <div className="flex-1">
        <label className="text-gray-700 flex items-center">
          <FaMapMarkerAlt className="text-yellow-500 mr-2" />
          Longitude
        </label>
        <input
          type="number"
          name="longitude"
          value={gymData.longitude ?? ""}
          onChange={onChange}
          className="w-full p-2 border rounded"
          step="any"
        />
      </div>
      <button
        type="button"
        onClick={onGetLocation}
        className="bg-yellow-500 text-white btn"
        disabled={loadingLocation}
      >
        {loadingLocation ? "Fetching Location..." : "Get Current Location"}
      </button>
    </div>
  </FormCardWrapper>
);


const FacilitiesField = ({ gymData, onChange }) => {
  // Define a list of facilities with default icons
  const defaultFacilitiesList = facilitiesList;

  // Create facilitiesList including any new facilities from gymData.facilities
  const newFacilitiesList = [
    ...defaultFacilitiesList,
    ...(gymData.facilities || [])
      .filter(
        (facility) =>
          !defaultFacilitiesList.some((item) => item.name === facility)
      )
      .map((facility) => ({
        name: facility,
        icon: defaultIcon,
      })),
  ];

  // State to manage the new facility input value
  const [newFacility, setNewFacility] = useState("");

  // Handle the input change
  const handleInputChange = (e) => {
    setNewFacility(e.target.value);
  };

  // Handle the add button click
  const handleAddClick = () => {
    const trimmedValue = newFacility.trim();
    if (trimmedValue) {
      const inputFacilities = trimmedValue
        .split(",")
        .map((facility) => facility.trim())
        .filter(Boolean);

      // Call the onChange function with the new facilities
      onChange({
        target: {
          name: "manual",
          value: inputFacilities.join(","),
        },
      });

      // Clear the input field
      setNewFacility("");
    }
  };

  return (
    <FormCardWrapper title="Facilities">
      <div className="flex flex-col">
        <div className="flex items-center mb-2">
          <FaClipboardList className="text-xl text-purple-500 mr-2" />
          <label className="text-gray-700 font-semibold">Facilities</label>
        </div>

        <div className="flex items-center mb-4">
          <input
            type="text"
            value={newFacility}
            placeholder="Type facility name"
            onChange={handleInputChange}
            className="flex-1 input input-bordered w-full mr-2"
          />
          <button
            type="button"
            onClick={handleAddClick}
            className="bg-yellow-500 text-white btn"
          >
            Add
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {newFacilitiesList.map(({ name, icon }, index) => (
            <div key={index} className="flex items-center space-x-2">
              {icon}
              <input
                type="checkbox"
                name={name}
                checked={gymData.facilities?.includes(name) || false}
                onChange={onChange}
                className="form-checkbox"
              />
              <span className="text-gray-800">{name}</span>
            </div>
          ))}
        </div>
      </div>
    </FormCardWrapper>
  );
};

const SocialMediaLinks = ({ socialMediaLinks, onChange }) => {
  const [links, setLinks] = useState(socialMediaLinks);

  useEffect(() => {
    setLinks(socialMediaLinks);
  }, [socialMediaLinks]);

  const handleInputChange = (index, event) => {
    const newLinks = [...links];
    newLinks[index] = event.target.value;
    setLinks(newLinks);
  };

  const handleAddLink = () => {
    setLinks([...links, ""]);
    onChange(links);
  };

  const handleRemoveLink = (index) => {
    const newLinks = links.filter((_, i) => i !== index);
    setLinks(newLinks);
    handleSave();
  };

  const handleSave = () => {
    onChange(links);
  };

  return (
    <FormCardWrapper title="Social Media Links">
      <div className="flex flex-col">
        <div className="flex flex-wrap mb-4">
          {Object.keys(socialMediaIcons).map((key) => (
            <div key={key} className="flex items-center space-x-2 mr-4 mb-2">
              {socialMediaIcons[key]}
              <span className="text-gray-800">{key}</span>
            </div>
          ))}
        </div>
        {links.map((link, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={link}
              onChange={(e) => handleInputChange(index, e)}
              placeholder="Enter social media link"
              className="input input-bordered w-full mr-2"
            />
            <button
              type="button"
              onClick={() => handleRemoveLink(index)}
              className="bg-red-500 text-white btn"
            >
              Remove
            </button>
          </div>
        ))}
        <div className="flex mt-4 space-x-4">
          <button
            type="button"
            onClick={handleAddLink}
            className="bg-green-500 text-white btn"
          >
            Add Link
          </button>
          <button
            type="button"
            onClick={handleSave}
            className="bg-yellow-500 text-white btn"
          >
            Save Links
          </button>
        </div>
      </div>
    </FormCardWrapper>
  );
};

const inputFields = [
  {
    label: "Gym Name*",
    name: "gymName",
    type: "text",
    icon: <FaBuilding className="text-xl text-blue-500 mr-2" />,
    required: true,
  },
  {
    label: "Contact Number*",
    name: "contactNumber",
    type: "text",
    icon: <FaPhone className="text-xl text-green-500 mr-2" />,
    required: true,
  },
  {
    label: "Email Address",
    name: "emailAddress",
    type: "email",
    icon: <FaMapMarkerAlt className="text-xl text-red-500 mr-2" />,
  },
  {
    label: "Address*",
    name: "address",
    type: "text",
    icon: <FaEnvelope className="text-xl text-yellow-500 mr-2" />,
    required: true,
  },
  {
    label: "Branch*",
    name: "branch",
    type: "text",
    icon: <FaStore  className="text-xl text-black mr-2" />,
    required: true,
  },
  {
    label: "Website",
    name: "website",
    type: "url",
    icon: <FaGlobe className="text-xl text-purple-500 mr-2" />,
  },
  {
    label: "Operating Hours",
    name: "operatingHours",
    type: "text",
    icon: <FaClock className="text-xl text-orange-500 mr-2" />,
  },
];
