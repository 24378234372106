import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/userSlice";
import { useGetGymsByUserQuery } from "../../redux/gymApiSlice";
import FetchWrapper from "../../components/FetchWrapper";
import { useEffect, useState } from "react";
import { setGym } from "../../redux/gymSlice";
import { FaStore } from "react-icons/fa";

const GymSelector = () => {
  const { userId } = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [redirecting, setRedirecting] = useState(false);

  const {
    data: gyms,
    isLoading,
    isError,
    error,
  } = useGetGymsByUserQuery({
    userId,
    pageNo: 0,
    pageSize: 25,
    sortBy: "gymName",
    sortOrder: "asc",
  });

  useEffect(() => {
    if (gyms?.content?.length === 1 && !redirecting) {
      setRedirecting(true);
      const singleGym = gyms.content[0];
      dispatch(
        setGym({
          gymId: singleGym.id,
          gymBranch: singleGym.branch,
          gymName: singleGym.gymName,
          gymAddress: singleGym.address,
          gymContact: singleGym.contactNumber.join(", "),
          gymEmail: singleGym.emailAddress,
          gymLogourl: singleGym.gymLogoImageUrl,
        })
      );
      navigate(`/home`);
    }

    if (gyms?.content?.length === 0 && !redirecting) {
      setRedirecting(true);
      navigate("/add");
    }
  }, [gyms, navigate, redirecting, dispatch]);

  // Handle loading and error states
  if (isLoading) {
    return <FetchWrapper isLoading={true} isError={false} />;
  }

  if (isError) {
    return <FetchWrapper isLoading={false} isError={true} error={error} />;
  }

  // Render list of gyms if no redirection
  if (gyms?.content?.length > 1) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="flex flex-col items-center p-6 space-y-6">
          <h1 className="text-4xl font-bold mb-4 text-center text-gray-800">
            Select a Gym
          </h1>
          <div className="flex flex-row gap-6 flex-wrap w-full max-w-6xl">
            {gyms?.content?.map((gym) => (
              <div
                key={gym.id}
                className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 w-72 max-w-sm"
              >
                <figure className="bg-gray-200 h-48 flex items-center justify-center">
                  {gym.gymLogoImageUrl ? (
                    <img
                      src={gym.gymLogoImageUrl}
                      alt={gym.gymName}
                      className="object-cover h-full w-full"
                    />
                  ) : (
                    <FaStore className="text-4xl text-gray-500" />
                  )}
                </figure>
                <div className="p-6">
                  <h2 className="text-2xl font-semibold text-gray-800">
                    {gym.gymName}
                  </h2>
                  <p className="text-gray-600 mt-1">{gym.branch}</p>
                  <div className="mt-4">
                    <button
                      onClick={() => {
                        dispatch(
                          setGym({
                            gymId: gym.id,
                            gymBranch: gym.branch,
                            gymName: gym.gymName,
                            gymAddress: gym.address,
                            gymContact: gym.contactNumber.join(", "),
                            gymEmail: gym.emailAddress, 
                            gymLogourl: gym.gymLogoImageUrl, 
                          })
                        );
                        navigate(`/home`);
                      }}
                      className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors"
                    >
                      Manage Gym
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  // Return null if redirection is happening
  return null;
};

export default GymSelector;
