import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetGymByIdQuery,
  useUpdateGymMutation,
} from "../../redux/gymApiSlice";
import GymForm from "./GymForm";
import FullScreenLoading from "../../components/FullScreenLoading";

const EditGymScreen = () => {
  const { gymId } = useParams();
  const { data: gymData, isLoading: isFetching } = useGetGymByIdQuery(gymId);
  const [updateGym, { isLoading }] = useUpdateGymMutation();
  const [initialData, setInitialData] = useState({
    gymName: null,
    gymLogoImageUrl: null,
    address: null,
    branch:null,
    contactNumber: null,
    emailAddress: null,
    websiteURL: null,
    openingHours: null,
    facilities: null,
    latitude: null,
    longitude: null,
    socialMediaLinks: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (gymData) {
      setInitialData({
        gymName: gymData.gymName || null,
        gymLogoImageUrl: gymData.gymLogoImageUrl || null,
        address: gymData.address || null,
        branch: gymData.branch || null,
        contactNumber: gymData.contactNumber || null,
        emailAddress: gymData.emailAddress || null,
        websiteURL: gymData.websiteURL || null,
        openingHours: gymData.openingHours || null,
        facilities: gymData.facilities || null,
        latitude: gymData.latitude || null,
        longitude: gymData.longitude || null,
        socialMediaLinks:gymData.socialMediaLinks || [],
      });
    }
  }, [gymData]);

  const handleSubmit = async ({ gymData, wifiFile, logoFile }) => {
    await updateGym({ gymId, gymData, wifiFile, logoFile }).unwrap();
    navigate(-1); // Go back to the previous page after update
  };

  if (isFetching || !initialData) return <FullScreenLoading />;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Edit Gym</h1>
      <GymForm
        initialData={initialData}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EditGymScreen;
