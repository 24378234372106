import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetPricingPlansByGymIdQuery,
  useCreatePricingPlanMutation,
  useUpdatePricingPlanMutation,
  useDeletePricingPlanMutation,
} from "../../redux/gymPricingPlanApiSlice";
import FetchWrapper from "../../components/FetchWrapper";
import useSnackbar from "../../hooks/useSnackbar";
import { FaEdit, FaTrash } from "react-icons/fa";
import FormCardWrapper from "../../components/FormCardWrapper";

const PlanForm = ({ plan, setPlan, onSubmit, onCancel }) => {
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlan((prevPlan) => ({
      ...prevPlan,
      [name]: name.includes("Price")
        ? value === ""
          ? ""
          : parseFloat(value)
        : value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    let tempErrors = {};
    if (!plan.planName) tempErrors.planName = "Plan Name is required.";
    if (
      [
        "monthlyPrice",
        "threeMonthPrice",
        "sixMonthPrice",
        "twelveMonthPrice",
      ].some((priceType) => plan[priceType] === "" || plan[priceType] <= 0)
    ) {
      tempErrors.price =
        "All price fields must be filled and greater than zero.";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      onSubmit();
    }
  };

  return (
    <FormCardWrapper title={"Gym Plans"}>
      <form onSubmit={handleSubmit}>
        <div className="form-control mb-4">
          <label className="label text-sm font-medium">Plan Name</label>
          <input
            type="text"
            name="planName"
            value={plan.planName}
            onChange={handleInputChange}
            placeholder="Enter Plan Name"
            className="input input-bordered"
            required
          />
          {errors.planName && (
            <div className="text-red-500">{errors.planName}</div>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {[
            "monthlyPrice",
            "threeMonthPrice",
            "sixMonthPrice",
            "twelveMonthPrice",
          ].map((priceType, idx) => (
            <div key={idx} className="form-control">
              <label className="label text-sm font-medium">
                {priceType.replace(/([A-Z])/g, " $1")}
              </label>
              <input
                type="number"
                name={priceType}
                value={plan[priceType]}
                onChange={handleInputChange}
                placeholder={`Enter ${priceType.replace(/([A-Z])/g, " $1")}`}
                className="input input-bordered"
                required
              />
            </div>
          ))}
          {errors.price && (
            <div className="text-red-500 md:col-span-2">{errors.price}</div>
          )}
        </div>

        <div className="form-control mb-4 mt-4">
          <label className="label text-sm font-medium">Extra Features</label>
          <textarea
            name="extraFeatures"
            value={plan.extraFeatures}
            onChange={handleInputChange}
            placeholder="Enter Extra Features"
            className="textarea textarea-bordered"
          />
        </div>

        <div className="flex justify-center space-x-2 mt-4">
          <button type="submit" className="btn btn-success text-white w-1/3">
            Save
          </button>
        </div>
      </form>
    </FormCardWrapper>
  );
};

// Component to display a pricing plan
const PlanCard = ({ plan, onEdit, onDelete }) => {
  return (
    <div className="card bg-base-100 shadow-lg p-4">
      <h3 className="font-semibold text-lg mb-2">{plan.planName}</h3>
      <p>Monthly Price: Rs {plan.monthlyPrice}</p>
      <p>3 Month Price: Rs {plan.threeMonthPrice}</p>
      <p>6 Month Price: Rs {plan.sixMonthPrice}</p>
      <p>12 Month Price: Rs {plan.twelveMonthPrice}</p>
      <p>Extra Features: {plan.extraFeatures}</p>
      <div className="flex space-x-2 mt-4">
        <button onClick={onEdit} className="btn btn-primary btn-sm">
          <FaEdit className="mr-1" /> Edit
        </button>
        <button onClick={onDelete} className="btn btn-error btn-sm">
          <FaTrash className="mr-1" /> Delete
        </button>
      </div>
    </div>
  );
};

const GymPricingScreen = () => {
  const { gymId } = useParams();
  const {
    data: pricingPlans,
    isLoading,
    isError,
    error,
  } = useGetPricingPlansByGymIdQuery({ gymId });

  const [createPricingPlan] = useCreatePricingPlanMutation();
  const [updatePricingPlan] = useUpdatePricingPlanMutation();
  const [deletePricingPlan] = useDeletePricingPlanMutation();

  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const initialPlanState = {
    planName: "",
    monthlyPrice: "",
    threeMonthPrice: "",
    sixMonthPrice: "",
    twelveMonthPrice: "",
    extraFeatures: "",
  };

  const [newPlan, setNewPlan] = useState(initialPlanState);
  const [editingPlanId, setEditingPlanId] = useState(null);
  const [editingPlan, setEditingPlan] = useState(initialPlanState);

  // Create a new pricing plan
  const handleCreatePlan = async () => {
    try {
      const pricingPlanData = {
        ...newPlan,
        monthlyPrice: parseFloat(newPlan.monthlyPrice) || 0,
        threeMonthPrice: parseFloat(newPlan.threeMonthPrice) || 0,
        sixMonthPrice: parseFloat(newPlan.sixMonthPrice) || 0,
        twelveMonthPrice: parseFloat(newPlan.twelveMonthPrice) || 0,
      };
      await createPricingPlan({ gymId, pricingPlanData }).unwrap();
      showSnackbar("Pricing Plan Created Successfully!", "success");
      setNewPlan(initialPlanState);
    } catch (error) {
      showSnackbar("Error creating pricing plan.", "error");
    }
  };

  // Update an existing pricing plan
  const handleUpdatePlan = async (planId) => {
    try {
      const pricingPlanData = {
        ...editingPlan,
        monthlyPrice: parseFloat(editingPlan.monthlyPrice) || 0,
        threeMonthPrice: parseFloat(editingPlan.threeMonthPrice) || 0,
        sixMonthPrice: parseFloat(editingPlan.sixMonthPrice) || 0,
        twelveMonthPrice: parseFloat(editingPlan.twelveMonthPrice) || 0,
      };
      await updatePricingPlan({ planId, pricingPlanData }).unwrap();
      showSnackbar("Pricing Plan Updated Successfully!", "success");
      setEditingPlanId(null);
      setEditingPlan(initialPlanState);
    } catch (error) {
      showSnackbar("Error updating pricing plan.", "error");
    }
  };

  // Delete a pricing plan
  const handleDeletePlan = async (planId) => {
    try {
      await deletePricingPlan({ planId, gymId }).unwrap();
      showSnackbar("Pricing Plan Deleted Successfully!", "success");
    } catch (error) {
      showSnackbar("Error deleting pricing plan.", "error");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-6">Pricing Plans</h2>
      <FetchWrapper isLoading={isLoading} isError={isError} error={error}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {pricingPlans &&
            pricingPlans.map((plan) => (
              <div key={plan.id}>
                {editingPlanId === plan.id ? (
                  <div className="card bg-base-100 shadow-lg p-4">
                    <h3 className="text-lg font-semibold mb-4">
                      Edit Pricing Plan
                    </h3>
                    <PlanForm
                      plan={editingPlan}
                      setPlan={setEditingPlan}
                      onSubmit={() => handleUpdatePlan(plan.id)}
                      onCancel={() => {
                        setEditingPlanId(null);
                        setEditingPlan(initialPlanState);
                      }}
                    />
                  </div>
                ) : (
                  <PlanCard
                    plan={plan}
                    onEdit={() => {
                      setEditingPlanId(plan.id);
                      setEditingPlan(plan);
                    }}
                    onDelete={() => handleDeletePlan(plan.id)}
                  />
                )}
              </div>
            ))}
        </div>

        {/* Form to add a new pricing plan */}
        <div className="mt-10 card bg-base-100 shadow-lg p-6">
          <h3 className="text-lg font-semibold mb-4">Add New Pricing Plan</h3>
          <PlanForm
            plan={newPlan}
            setPlan={setNewPlan}
            onSubmit={handleCreatePlan}
          />
        </div>
      </FetchWrapper>

      {/* Snackbar Component */}
      <SnackbarComponent />
    </div>
  );
};

export default GymPricingScreen;
