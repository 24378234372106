/* eslint-disable no-template-curly-in-string */
import React, { useState, useRef } from "react";
import { useSendSMSMutation } from "../../redux/smsApiSlice";
import useSnackbar from "../../hooks/useSnackbar";
import { useSelector } from "react-redux";
import { selectGym } from "../../redux/gymSlice";

const AddSmsScreen = () => {
  const { gymId } = useSelector(selectGym);
  const [message, setMessage] = useState("");
  const [sendType, setSendType] = useState("clients"); // 'clients' or 'phoneNumbers'
  const [phoneNumbers, setPhoneNumbers] = useState("");
  const textareaRef = useRef(null);

  const [sendSMS, { isLoading }] = useSendSMSMutation();
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const [isSending, setIsSending] = useState(false);

  const insertTemplateVariable = (variable) => {
    if (textareaRef.current) {
      const startPos = textareaRef.current.selectionStart;
      const endPos = textareaRef.current.selectionEnd;
      const newValue =
        message.substring(0, startPos) +
        variable +
        message.substring(endPos, message.length);
      setMessage(newValue);
    }
  };

  const calculateSMSDetails = (text) => {
    const textLength = text.length;
    let pages = 1;
    let charactersInCurrentPage = textLength;
    let remainingLettersInCurrentPage = 160 - textLength;

    if (textLength > 160) {
      pages = Math.ceil((textLength - 160) / 153) + 1;
      charactersInCurrentPage = (textLength - 160) % 153 || 153;
      remainingLettersInCurrentPage = 153 - charactersInCurrentPage;
    }

    return {
      letters: textLength,
      pages: pages,
      charactersInCurrentPage: charactersInCurrentPage,
      remainingLettersInCurrentPage: remainingLettersInCurrentPage,
      perPage: pages === 1 ? 160 : 153,
      smsCost: pages, // Cost of the SMS is equal to the number of pages
    };
  };

  const smsDetails = calculateSMSDetails(message);

  const handleSend = async (type = null) => {
    setIsSending(true);
    try {
      const smsRequestDto = { smsContent: message, phoneNumbers, type, gymId };
      await sendSMS(smsRequestDto).unwrap();
      showSnackbar("SMS sent successfully", "success");
      // Reset form state
      setMessage("");
      setPhoneNumbers("");
      setSendType("clients");
    } catch (error) {
      console.error("Failed to send SMS:", error);
      showSnackbar("Failed to send SMS: " + (error.data?.message || "Unknown error"), "error");
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-xl font-semibold mb-4 text-gray-800">Add SMS</h1>

      <div className="mb-4">
        <button
          className={`px-4 py-2 mr-2 rounded ${
            sendType === "clients"
              ? "bg-blue-500 text-white"
              : "bg-gray-300 text-gray-800"
          }`}
          onClick={() => setSendType("clients")}
          disabled={isLoading || isSending}
        >
          Send to Clients
        </button>
        <button
          className={`px-4 py-2 rounded ${
            sendType === "phoneNumbers"
              ? "bg-blue-500 text-white"
              : "bg-gray-300 text-gray-800"
          }`}
          onClick={() => setSendType("phoneNumbers")}
          disabled={isLoading || isSending}
        >
          Send with Phone No.
        </button>
      </div>

      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="message"
        >
          Message:
        </label>
        <textarea
          id="message"
          ref={textareaRef}
          className="w-full p-2 border rounded"
          rows="4"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          maxLength={720}
        />
      </div>

      {sendType === "clients" && (
        <div className="flex space-x-2 mb-4">
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-1 px-2 rounded"
            onClick={() => insertTemplateVariable("${client_name}")}
          >
            ${`{client_name}`}
          </button>
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-1 px-2 rounded"
            onClick={() => insertTemplateVariable("${expiry_date}")}
          >
            ${`{expiry_date}`}
          </button>
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-1 px-2 rounded"
            onClick={() => insertTemplateVariable("${business_name}")}
          >
            ${`{business_name}`}
          </button>
        </div>
      )}

      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <p className="text-gray-600 text-sm">
            Chars: {smsDetails.letters} / 720
          </p>
          <p className="text-gray-600 text-sm">Page: {smsDetails.pages}</p>
          <p className="text-gray-600 text-sm">
            Per Page: {smsDetails.perPage}
          </p>
        </div>
        <div>
          <p className="text-gray-600 text-sm">
            Remain: {smsDetails.remainingLettersInCurrentPage}
          </p>
          <p className="text-gray-600 text-sm">Info: Each page costs 1 SMS</p>
        </div>
      </div>
      <div className="text-red-600 font-bold mb-4">
        SMS Cost: {smsDetails.smsCost}
      </div>

      {sendType === "clients" ? (
        <div className="flex space-x-4 mb-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={() => handleSend("ALL")}
            disabled={isLoading || isSending}
          >
            {isSending ? "Sending..." : "Send to All Clients"}
          </button>
          <button
            className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
            onClick={() => handleSend("EXPIRED")}
            disabled={isLoading || isSending}
          >
            {isSending ? "Sending..." : "Send to Expired Clients"}
          </button>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            onClick={() => handleSend("NON_EXPIRED")}
            disabled={isLoading || isSending}
          >
            {isSending ? "Sending..." : "Send to Non-Expired Members"}
          </button>
        </div>
      ) : (
        <>
          <h2 className="text-lg font-semibold mb-2 text-gray-800">
            Send to Certain Phone Numbers
          </h2>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="phoneNumbers"
            >
              Phone Numbers (separate with commas):
            </label>
            <textarea
              rows={4}
              id="phoneNumbers"
              type="text"
              className="w-full p-2 border rounded"
              placeholder="e.g., 9867770377,9867770378"
              value={phoneNumbers}
              onChange={(e) => setPhoneNumbers(e.target.value)}
            />
          </div>

          <button
            className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
            onClick={() => handleSend(null)}
            disabled={isLoading || isSending}
          >
            {isSending ? "Sending..." : "Send"}
          </button>
        </>
      )}

      {/* Render the Snackbar component */}
      <SnackbarComponent />
    </div>
  );
};

export default AddSmsScreen;
