import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { selectUser } from "../redux/userSlice";
import RouteConfig from "../config/RouteConfig";

const SuperAdminRoute = () => {
  const user = useSelector(selectUser);

  // Redirect to login if not authenticated
  if (!user.isAuthenticated) {
    return <Navigate to={RouteConfig.LOGIN} replace />;
  }

  // Redirect to Gym Portal if not superadmin
  if (!user.superAdmin || !user.adminPanel) {
    return <Navigate to={RouteConfig.GYM_SELECTOR} replace />;
  }

  return <Outlet />;
};

export default SuperAdminRoute;
