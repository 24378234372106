const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="bg-gray-800 text-white py-6">
      <div className="container mx-auto px-6 lg:px-8">
        <p className="text-center">© {currentYear} Byayam.com All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
