import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/userSlice";
import {
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from "../../redux/userApiSlice";
import { useNavigate } from "react-router-dom";
import DistrictDropdown from "../../components/DistrictDropdown";
import { FaUserAlt, FaPhone, FaHome, FaMapMarkerAlt } from "react-icons/fa";
import FormCardWrapper from "../../components/FormCardWrapper";

const EditProfileScreen = () => {
  const { userId } = useSelector(selectUser);
  const { data: user, isLoading: isFetchingUser } = useGetUserByIdQuery(userId);
  const [updateUser, { isLoading: isUpdatingUser }] = useUpdateUserMutation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    address: "",
    district: "",
  });

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || "",
        phone: user.phone || "",
        address: user.address || "",
        district: user.district || "",
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUser({ id: userId, userData: formData }).unwrap();
      navigate(-1); // Go back to the previous page or to a specific route
    } catch (error) {
      console.error("Failed to update user:", error);
    }
  };

  if (isFetchingUser) return <div>Loading...</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-xl font-bold mb-4">Edit Profile</h1>

      <FormCardWrapper title={"Profile"}>
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 md:grid-cols-2 gap-4"
        >
          <div className="flex flex-col">
            <div className="flex items-center mb-1">
              <FaUserAlt className="text-xl text-indigo-500 mr-2" />
              <label className="text-gray-700">Name</label>
            </div>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="flex flex-col">
            <div className="flex items-center mb-1">
              <FaPhone className="text-xl text-green-500 mr-2" />
              <label className="text-gray-700">Phone</label>
            </div>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="flex flex-col">
            <div className="flex items-center mb-1">
              <FaHome className="text-xl text-blue-500 mr-2" />
              <label className="text-gray-700">Address</label>
            </div>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="flex flex-col md:col-span-1">
            <div className="flex items-center mb-1">
              <FaMapMarkerAlt className="text-xl text-orange-500 mr-2" />
              <label className="text-gray-700">District</label>
            </div>
            <DistrictDropdown
              value={formData.district}
              onChange={handleChange}
            />
          </div>
          <div className="col-span-2">
            <button
              type="submit"
              className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
              disabled={isUpdatingUser}
            >
              {isUpdatingUser ? "Updating..." : "Update Profile"}
            </button>
          </div>
        </form>
      </FormCardWrapper>
    </div>
  );
};

export default EditProfileScreen;
