import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import FetchWrapper from "../../components/FetchWrapper";
import {
  useGetGymSMSSettingByIdQuery,
  useUpdateGymSMSSettingMutation,
} from "../../redux/gymSMSSettingApiSlice";
import { selectGym } from "../../redux/gymSlice";

const ScheduleScreen = () => {
  const { gymId } = useSelector(selectGym);
  const { data: userSMSSetting, error, isLoading } = useGetGymSMSSettingByIdQuery(gymId);
  const [updateUserSMSSetting] = useUpdateGymSMSSettingMutation();
  const [formState, setFormState] = useState(null);

  // Editing states for each template
  const [isEditing, setIsEditing] = useState({});

  useEffect(() => {
    if (userSMSSetting) {
      setFormState(userSMSSetting);
    }
  }, [userSMSSetting]);

  const handleChange = async (field) => {
    const updatedState = { ...formState, [field]: !formState[field] };
    setFormState(updatedState);
    await updateUserSMSSetting({ id: gymId, userSMSSetting: updatedState });
  };

  const handleTemplateChange = async (field, value) => {
    const updatedState = { ...formState, [field]: value };
    setFormState(updatedState);
    await updateUserSMSSetting({ id: gymId, userSMSSetting: updatedState });
  };

  const handleEditingState = (field, value) => {
    setIsEditing({ ...isEditing, [field]: value });
  };

  const settings = [
    {
      category: "Before Expiry SMS",
      color: "text-orange-600",
      items: [
        {
          label: "Send Before 7 Days Expired",
          field: "sendBefore7DaysExpired",
          templateField: "sendBefore7DaysExpiredTemplate",
        },
        {
          label: "Send Before 3 Days Expired",
          field: "sendBefore3DaysExpired",
          templateField: "sendBefore3DaysExpiredTemplate",
        },
        {
          label: "Send Before 1 Day Expired",
          field: "sendBefore1DayExpired",
          templateField: "sendBefore1DayExpiredTemplate",
        },
      ],
    },
    {
      category: "After Expiry SMS",
      color: "text-blue-600",
      items: [
        {
          label: "Send After 1 Day Expired",
          field: "sendAfter1DayExpired",
          templateField: "sendAfter1DayExpiredTemplate",
        },
        {
          label: "Send After 3 Days Expired",
          field: "sendAfter3DaysExpired",
          templateField: "sendAfter3DaysExpiredTemplate",
        },
        {
          label: "Send After 7 Days Expired",
          field: "sendAfter7DaysExpired",
          templateField: "sendAfter7DaysExpiredTemplate",
        },
      ],
    },
    {
      category: "Other SMS",
      color: "text-green-600",
      items: [
        {
          label: "Send Membership Renewed",
          field: "sendMembershipRenewed",
          templateField: "sendMembershipRenewedTemplate",
        },
        {
          label: "Send Birthday",
          field: "sendBirthday",
          templateField: "sendBirthdayTemplate",
        },
      ],
    },
  ];

  return (
    <FetchWrapper isLoading={isLoading} isError={!!error} error={error}>
      <div className="p-6 bg-white rounded-lg shadow-md mx-auto max-w-full">
        <h1 className="text-2xl font-semibold mb-6 text-gray-800">User SMS Settings</h1>
        {formState && (
          <>
            {settings.map((section, idx) => (
              <div key={idx} className="mb-6">
                <h2 className={`text-xl font-semibold mb-4 ${section.color}`}>{section.category}</h2>
                {section.items.map((item) => (
                  <div key={item.field}>
                    <ToggleSwitch
                      label={item.label}
                      checked={formState[item.field]}
                      onChange={() => handleChange(item.field)}
                    />
                  </div>
                ))}
              </div>
            ))}

            <div className="mb-4">
              <h2 className="text-xl font-semibold text-gray-800">Default Messages</h2>
              <span className="text-gray-600 text-sm">
                SMS costs 1 credit per page, with a maximum of 160 characters per page.
              </span>
            </div>
            <div className="grid grid-cols-1 gap-6">
              {settings.map((section, idx) => (
                <div key={idx}>
                  {section.items.map((item) => (
                    <EditableTemplate
                      key={item.templateField}
                      label={item.label}
                      value={formState[item.templateField]}
                      onSave={(value) => handleTemplateChange(item.templateField, value)}
                      isEditing={isEditing[item.templateField] || false}
                      setIsEditing={(value) => handleEditingState(item.templateField, value)}
                    />
                  ))}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </FetchWrapper>
  );
};

const ToggleSwitch = ({ label, checked, onChange }) => (
  <div className="flex items-center justify-between mb-4">
    <label className="text-gray-700">{label}</label>
    <div
      className={`relative w-12 h-6 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-200 ${
        checked ? "bg-green-500" : "bg-gray-300"
      }`}
      onClick={onChange}
    >
      <div
        className={`absolute left-0 w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-200 ${
          checked ? "translate-x-6" : ""
        }`}
      ></div>
    </div>
  </div>
);

const EditableTemplate = ({ label, value, onSave, isEditing, setIsEditing }) => {
  const [tempValue, setTempValue] = useState(value);
  const textareaRef = useRef(null);

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const handleSave = async () => {
    await onSave(tempValue);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setTempValue(value);
    setIsEditing(false);
  };

  const insertTemplateVariable = (variable) => {
    if (textareaRef.current) {
      const { selectionStart, selectionEnd } = textareaRef.current;
      const newValue =
        tempValue.substring(0, selectionStart) +
        variable +
        tempValue.substring(selectionEnd);
      setTempValue(newValue);
    }
  };

  const calculateSMSDetails = (text = "") => {
    const textLength = text.length;
    let perPage = textLength <= 160 ? 160 : 153;
    let pages = Math.ceil(textLength / perPage);
    let remainingLettersInCurrentPage = pages * perPage - textLength;

    return {
      letters: textLength,
      pages,
      smsCostReduction: pages,
      remainingLettersInCurrentPage,
      perPage,
    };
  };

  const smsDetails = calculateSMSDetails(tempValue);

  return (
    <div className="mb-6">
      <div className="flex justify-between items-center mb-2">
        <div>
          <label className="text-gray-700 font-semibold">{label}</label>
          <span className="text-red-600 border text-sm font-medium py-1 px-3 rounded ml-2">
            SMS Reduce: -{smsDetails.smsCostReduction} SMS
          </span>
        </div>
        {isEditing ? (
          <div>
            <button
              className="bg-green-500 hover:bg-green-600 text-white text-sm font-medium py-1 px-3 rounded mr-2"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              className="bg-red-500 hover:bg-red-600 text-white text-sm font-medium py-1 px-3 rounded"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        ) : (
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-1 px-3 rounded"
            onClick={() => setIsEditing(true)}
          >
            Edit
          </button>
        )}
      </div>
      {isEditing ? (
        <div>
          <div className="flex space-x-2 mb-4">
            {["client_name", "expiry_date", "business_name"].map((variable) => (
              <button
                key={variable}
                className="bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-1 px-2 rounded"
                onClick={() => insertTemplateVariable(`\${${variable}}`)}
              >
                ${`{${variable}}`}
              </button>
            ))}
          </div>
          <p className="text-gray-600 text-sm mb-2">
            Use these buttons to dynamically insert the client's name, expiry date, and your
            gym/store name into the message.
          </p>
          <textarea
            ref={textareaRef}
            className="w-full mt-2 p-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
            value={tempValue}
            onChange={(e) => setTempValue(e.target.value)}
            rows="4"
            maxLength={720}
          />
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <p className="text-gray-600 text-sm">Chars: {smsDetails.letters} / 720</p>
              <p className="text-gray-600 text-sm">Page: {smsDetails.pages}</p>
              <p className="text-gray-600 text-sm">Per Page: {smsDetails.perPage}</p>
            </div>
            <div>
              <p className="text-gray-600 text-sm">
                Remain: {smsDetails.remainingLettersInCurrentPage}
              </p>
              <p className="text-gray-600 text-sm">Info: Each page costs 1 SMS</p>
            </div>
          </div>
        </div>
      ) : (
        <p className="mt-2 p-2 bg-gray-100 border border-gray-300 rounded-lg whitespace-pre-wrap">
          {value}
        </p>
      )}
    </div>
  );
};

export default ScheduleScreen;
