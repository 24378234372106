import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useGetInvoiceByIdQuery } from "../../../redux/clientInvoiceApiSlice";
import FetchWrapper from "../../../components/FetchWrapper";
import Table from "../../../components/Table";
import { FaCheckCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectGym } from "../../../redux/gymSlice";
import useSnackbar from "../../../hooks/useSnackbar";
import { useDeletePaymentMutation } from "../../../redux/clientPaymentApiSlice";
import ConfirmDialog from "../../../components/ConfirmDialog"; // Import ConfirmDialog

// Mini Component for Header
const InvoiceHeader = ({ gym }) => (
  <div className="flex justify-between items-center border-b pb-4">
    <div className="flex items-center space-x-4">
      {gym.gymLogourl && (
        <img src={gym.gymLogourl} alt="Gym Logo" className="w-24 h-24" />
      )}
      <div>
        <h1 className="text-3xl font-bold">{gym.gymName}</h1>
        <p className="text-gray-600">
          {gym.gymBranch} | {gym.gymAddress}
        </p>
        <p className="text-gray-600">
          Contact: {gym.gymContact} {gym.gymEmail && `| Email: ${gym.gymEmail}`}
        </p>
      </div>
    </div>
  </div>
);

// Mini Component for Client Info
const ClientInfo = ({ client, invoice }) => (
  <div className="flex justify-between items-center py-6 border-b border-gray-200 mb-2">
    <div>
      <h2 className="text-2xl font-bold">Bill To:</h2>
      <p className="text-gray-700 font-semibold">{client?.name}</p>
      <p className="text-gray-700">{client?.phone}</p>
    </div>
    <div className="text-right">
      <h2 className="text-2xl font-bold">Invoice Details</h2>
      <p className="text-gray-700">Invoice ID: {invoice?.id}</p>
      <p className="text-gray-700">
        Date: {dayjs(invoice?.createdAt).format("YYYY-MM-DD")}
      </p>
    </div>
  </div>
);

const InvoiceScreen = () => {
  const navigate = useNavigate();
  const { invoiceId } = useParams();
  const gym = useSelector(selectGym);
  const { showSnackbar, SnackbarComponent } = useSnackbar(); // Initialize useSnackbar

  const [deletePayment] = useDeletePaymentMutation(); // Initialize useDeletePaymentMutation
  const [isDialogOpen, setIsDialogOpen] = useState(false); // State for dialog visibility
  const [paymentToDelete, setPaymentToDelete] = useState(null); // State for payment to delete

  const {
    data: invoice,
    isLoading,
    isError,
    error,
  } = useGetInvoiceByIdQuery(invoiceId);

  const headers = {
    values: ["Date", "Amount", "Type"],
  };

  const rows = invoice?.paymentDtoList?.map((payment) => ({
    id: payment.id,
    Date: dayjs(payment.paymentDate).format("YYYY-MM-DD"),
    Amount: `Rs ${payment.amount}`,
    Type: payment.paymentType,
  }));

  // Action functions
  const handleEditPayment = (payment) => {
    navigate(`edit-payment/${payment.id}`);
  };

  const handleDeletePayment = (payment) => {
    setPaymentToDelete(payment);
    setIsDialogOpen(true); // Open the confirmation dialog
  };

  const confirmDeletePayment = async () => {
    try {
      await deletePayment(paymentToDelete.id).unwrap();
      showSnackbar("Payment deleted successfully!", "success");
    } catch (error) {
      showSnackbar(
        "Failed to delete payment. " + (error.data?.message || "Unknown error"),
        "error"
      );
    } finally {
      setIsDialogOpen(false); // Close the dialog
      setPaymentToDelete(null);
    }
  };

  // Function to print only the invoice content
  const printInvoice = () => {
    const printContents = document.getElementById("invoice-content").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // Reload the page to restore the original view
  };

  const addClientPayment = () => {
    navigate(`add-payment`);
  };

  const editInvoice = () => {
    navigate(`edit`);
  };

  return (
    <div className="container mx-auto p-4">
      <FetchWrapper
        isLoading={isLoading}
        isError={isError}
        error={error}
        isEmptyList={!isLoading && !invoice}
        emptyListInfo="invoice"
      >
        {invoice && (
          <>
            {/* Controls */}
            <div className="flex gap-4 items-center mt-4 mb-2">
              <button
                onClick={addClientPayment}
                className="btn btn-primary btn-outline"
              >
                Add Client Payment
              </button>
              <button
                onClick={editInvoice}
                className="btn btn-primary btn-outline"
              >
                Edit Invoice
              </button>
              <button
                onClick={printInvoice}
                className="btn btn-primary btn-outline"
              >
                Print Invoice
              </button>
            </div>

            {/* Invoice Display */}
            <div id="invoice-content">
              <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg print:border print:border-gray-400">
                {/* Gym Header */}
                <InvoiceHeader gym={gym} />

                {/* Client Information */}
                <ClientInfo client={invoice.basicClientDto} invoice={invoice} />

                <div className="grid grid-cols-2 gap-4 mb-6">
                  <div>
                    <h3 className="text-lg font-semibold">Plan Name:</h3>
                    <p>{invoice.pricingPlanDto.planName}</p>
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">
                      Membership Duration:
                    </h3>
                    <p>
                      {invoice.membershipStartDate} -{" "}
                      {invoice.membershipEndDate}
                    </p>
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Total Amount:</h3>
                    <p>Rs {invoice.totalAmount}</p>
                  </div>

                  <div>
                    <h3 className="text-lg font-semibold">
                      Discounted Amount:
                    </h3>
                    <p>Rs {invoice.discountedAmount}</p>
                  </div>

                  <div>
                    <h3 className="text-lg font-semibold">Amount Paid:</h3>
                    <p>Rs {invoice.amountPaid}</p>
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Amount Due:</h3>
                    {invoice.amountDue === 0 ? (
                      <div className="flex items-center space-x-2">
                        <FaCheckCircle className="text-green-600" />
                        <span>Cleared</span>
                      </div>
                    ) : (
                      <p>Rs {invoice.amountDue}</p>
                    )}
                  </div>
                </div>

                {/* Payment Information */}
                <div className="mb-6">
                  <h3 className="text-lg font-semibold">Payments:</h3>
                  {invoice.paymentDtoList.length > 0 ? (
                    <Table headers={headers} rows={rows} />
                  ) : (
                    <p className="text-red-500 font-semibold">
                      {`*You have a credit balance of  Rs ${invoice.amountDue}. Please clear it at your earliest convenience.`}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Duplicate Payment Table with Actions */}
            <div className="mt-6">
              <h3 className="text-lg font-semibold">Manage Payments:</h3>
              <Table
                headers={headers}
                rows={rows}
                actions={(row) => (
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEditPayment(row)}
                      className="btn btn-primary btn-sm"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeletePayment(row)}
                      className="btn btn-danger btn-sm"
                    >
                      Delete
                    </button>
                  </div>
                )}
              />
            </div>
          </>
        )}
      </FetchWrapper>
      <SnackbarComponent />
      {/* ConfirmDialog Component */}
      <ConfirmDialog
        isOpen={isDialogOpen}
        title="Confirm Delete"
        message="Are you sure you want to delete this payment? This action cannot be undone."
        onConfirm={(e) => {
          e.stopPropagation(); // Prevent event bubbling
          confirmDeletePayment();
        }}
        onCancel={(e) => {
          e.stopPropagation(); // Prevent event bubbling
          setIsDialogOpen(false);
        }}
      />
    </div>
  );
};

export default InvoiceScreen;
