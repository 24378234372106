import React from "react";
import { Bar } from "react-chartjs-2";
import FetchWrapper from "../../components/FetchWrapper";
import dayjs from "dayjs";
import { useGetMonthlySMSCountByGymIdQuery } from "../../redux/smsApiSlice";

const MonthlySMSCountChart = ({ gymId }) => {
  const {
    data: monthlySMSCount,
    error,
    isLoading,
  } = useGetMonthlySMSCountByGymIdQuery(gymId);

  const labels = monthlySMSCount
    ? monthlySMSCount.map((entry) =>
        dayjs(`${entry.year}-${entry.month}-01`).format("MMM YYYY")
      )
    : [];
  const counts = monthlySMSCount
    ? monthlySMSCount.map((entry) => entry.count)
    : [];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "SMS Count",
        data: counts,
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)", // Red
          "rgba(54, 162, 235, 0.6)", // Blue
          "rgba(255, 206, 86, 0.6)", // Yellow
          "rgba(75, 192, 192, 0.6)", // Green
          "rgba(153, 102, 255, 0.6)", // Purple
          "rgba(255, 159, 64, 0.6)", // Orange
          "rgba(199, 199, 199, 0.6)", // Grey
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(199, 199, 199, 1)",
        ],
        borderWidth: 1,
        borderRadius: 12, // Apply rounded corners only to the top
        borderSkipped: "start", // Skip the start side; for vertical bars, this means the bottom
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    maintainAspectRatio: true,
  };

  return (
    <FetchWrapper
      isError={error}
      error={error}
      isLoading={isLoading}
      isEmptyList={monthlySMSCount && monthlySMSCount.length === 0}
    >
      <div className="p-4 bg-white  rounded max-h-96">
        {" "}
        {/* Adjust height here */}
        <h2 className="text-xl font-bold mb-4">Monthly SMS Count</h2>
        <Bar data={data} options={options} />
      </div>
    </FetchWrapper>
  );
};

export default MonthlySMSCountChart;
