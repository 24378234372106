import { apiSlice } from "./apiSlice";

export const gymPricingPlanApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Fetch all pricing plans by Gym ID
    getPricingPlansByGymId: builder.query({
      query: ({gymId}) => ({
        url: `/api/pricing-plans/gyms/${gymId}`,
        method: "GET",
      }),
      providesTags: ["PricingPlans"],
    }),

    // Fetch a single pricing plan by its ID
    getPricingPlanById: builder.query({
      query: (planId) => ({
        url: `/api/pricing-plans/${planId}`,
        method: "GET",
      }),
      providesTags: ["PricingPlans"],
    }),

    // Create a new pricing plan for a gym
    createPricingPlan: builder.mutation({
      query: ({ gymId, pricingPlanData }) => ({
        url: `/api/pricing-plans/gyms/${gymId}`,
        method: "POST",
        body: pricingPlanData,
      }),
      invalidatesTags: ["PricingPlans"],
    }),

    // Update a pricing plan by its ID
    updatePricingPlan: builder.mutation({
      query: ({ planId, pricingPlanData }) => ({
        url: `/api/pricing-plans/${planId}`,
        method: "PUT",
        body: pricingPlanData,
      }),
      invalidatesTags: ["PricingPlans"],
    }),

    // Delete a pricing plan by its ID and Gym ID (authorization purpose)
    deletePricingPlan: builder.mutation({
      query: ({ planId, gymId }) => ({
        url: `/api/pricing-plans/${planId}/gyms/${gymId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PricingPlans"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetPricingPlansByGymIdQuery,
  useGetPricingPlanByIdQuery,
  useCreatePricingPlanMutation,
  useUpdatePricingPlanMutation,
  useDeletePricingPlanMutation,
} = gymPricingPlanApiSlice;
