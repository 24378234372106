import React from "react";
import ErrorComponent from "./ErrorComponent";
import FullScreenLoading from "./FullScreenLoading";

const FetchWrapper = ({
  isError,
  error,
  isLoading,
  isEmptyList = false,
  children,
  emptyListInfo
}) => {
  if (isLoading) {
    return <FullScreenLoading />;
  }

  if (isError) {
    let errMsg;
    if (error && error.data && error.data.message) {
      errMsg = error.data.message;
    } else if (error && error.message) {
      errMsg = error.message;
    } else {
      errMsg = "Something went wrong. Please try again later.";
    }

    return <ErrorComponent error={{ message: errMsg }} />;
  }

  if (isEmptyList) {
    return (
      <div className="flex justify-center items-center min-h-[30vh] bg-gray-200 mt-2 rounded-lg">
        <p className="text-lg">No {emptyListInfo} data available.</p>
      </div>
    );
  }

  return <>{children}</>;
};

export default FetchWrapper;
