import { NavLink } from "react-router-dom";
import RouteConfig from "../config/RouteConfig";

const publicRoutes = [
  { path: "/", label: "Home" },
  { path: RouteConfig.ABOUT, label: "About" },
  { path: RouteConfig.CONTACT, label: "Contact" },
  { path: RouteConfig.GYM_CMS, label: "Gym" },
  // { path: RouteConfig.PRICING, label: "Pricing" },
];

const PublicNavBar = () => {
  return (
    <nav className="bg-white sticky top-0 z-50 shadow-md">
      <div className="container mx-auto px-6 lg:px-16 py-3 flex justify-between items-center">
        <div className="hidden sm:block">
          <NavLink to="/" className="text-black text-2xl font-bold" aria-label="App Icon">
            Byayam.com
          </NavLink>
        </div>
        <div className="flex flex-1 justify-center sm:justify-end items-center space-x-4">
          {publicRoutes.map((route) => (
            <NavLink 
              to={route.path} 
              key={route.label} 
              className={({ isActive }) =>
                isActive ? "text-black border-b-2 border-purple-600" : "text-black hover:text-gray-700"
              }
            >
              {route.label}
            </NavLink>
          ))}
          <NavLink to={RouteConfig.LOGIN} className="bg-purple-600 text-white rounded-md px-4 py-2 hover:bg-purple-700 ml-auto sm:ml-0">
            Login
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default PublicNavBar;
