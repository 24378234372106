import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from "../../redux/userApiSlice";
import dayjs from "dayjs";
import FetchWrapper from "../../components/FetchWrapper";
import { useGetUserRolesQuery } from "../../redux/enumApiSlice";
import DynamicDropdown from "../../components/DynamicDropdown";
import useSnackbar from "../../hooks/useSnackbar";
import FormCardWrapper from "../../components/FormCardWrapper";

const ManageEditUserProfile = () => {
  const navigation = useNavigate();
  const { userId } = useParams();
  const { data: user, isLoading, isError, error } = useGetUserByIdQuery(userId);

  const [updateUser] = useUpdateUserMutation();

  const [formData, setFormData] = useState({
    role: "",
    totalSms: "",
    paymentExpiryDate: "",
  });

  const { showSnackbar, SnackbarComponent } = useSnackbar(); // Initialize snackbar

  // Prepopulate form fields with user data
  useEffect(() => {
    if (user) {
      setFormData({
        role: user.role,
        totalSms: user.totalSms,
        paymentExpiryDate: user.paymentExpiryDate,
      });
    }
  }, [user]);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUser({ id: userId, userData: formData }).unwrap();
      showSnackbar("User updated successfully!", "success");
      navigation(-1);
    } catch (error) {
      console.error("Failed to update user:", error);
      showSnackbar("Failed to update user. Please try again.", "error");
    }
  };

  return (
    <FetchWrapper isLoading={isLoading} isError={isError} error={error}>
      {user && (
        <div className="container mx-auto p-6 max-w-lg">
          <FormCardWrapper title={"Edit User Profile"}>
            <form onSubmit={handleSubmit} className="space-y-4">
              {/* Role Selection */}
              <DynamicDropdown
                label="Role"
                useQueryHook={useGetUserRolesQuery}
                value={formData.role}
                onSelect={(selectedRole) =>
                  setFormData((prev) => ({ ...prev, role: selectedRole }))
                }
              />

              {/* Total SMS */}
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Total SMS</span>
                </label>
                <input
                  type="number"
                  name="totalSms"
                  value={formData.totalSms}
                  onChange={handleChange}
                  className="input input-bordered w-full"
                  placeholder="Enter total SMS"
                />
              </div>

              {/* Payment Expiry Date */}
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Payment Expiry Date</span>
                </label>
                <input
                  type="date"
                  name="paymentExpiryDate"
                  value={dayjs(formData.paymentExpiryDate).format("YYYY-MM-DD")}
                  onChange={handleChange}
                  className="input input-bordered w-full"
                />
              </div>

              {/* Submit Button */}
              <div className="modal-action">
                <button type="submit" className="btn btn-primary w-full">
                  Update User
                </button>
              </div>
            </form>
          </FormCardWrapper>
        </div>
      )}
      {/* Snackbar */}
      <SnackbarComponent />
    </FetchWrapper>
  );
};

export default ManageEditUserProfile;
