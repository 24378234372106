import { apiSlice } from './apiSlice';


export const dashboardApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDashboardCardData: builder.query({
            query: (userId) => ({
                url: `/api/dashboard-cards/${userId}`,
            }),
            providesTags: ['DashboardMetrics', 'Payments']
        }),
        getMonthlyPayments: builder.query({
            query: (userId) => ({
                url: `/api/dashboard-monthly-payments/${userId}`,
            }),
            providesTags: ['MonthlyPayments']
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetDashboardCardDataQuery,
    useGetMonthlyPaymentsQuery, // New hook for fetching monthly payment data
} = dashboardApiSlice;
