import { apiSlice } from "./apiSlice";

export const gymSMSSettingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGymSMSSettingById: builder.query({
      query: (id) => ({
        url: `/api/gyms-sms-settings/${id}`,
      }),
      providesTags: ["GymsSMSSetting"],
    }),

    updateGymSMSSetting: builder.mutation({
      query: ({ id, userSMSSetting }) => ({
        url: `/api/gyms-sms-settings/${id}`,
        method: 'PUT',
        body: userSMSSetting,
      }),
      invalidatesTags: ["GymsSMSSetting"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGymSMSSettingByIdQuery,
  useUpdateGymSMSSettingMutation,
} = gymSMSSettingApiSlice;
