import React from "react";
import { useGetDistrictsQuery } from "../redux/enumApiSlice";

const DistrictDropdown = ({ onChange, value, required=false }) => {
  const { data: districts, isLoading, isError } = useGetDistrictsQuery();

  if (isLoading) return <p>Loading districts...</p>;
  if (isError) return <p>Error loading districts.</p>;

  return (
    <select
      name="district"
      id="district"
      onChange={onChange}
      value={value}
      required={required}
      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
    >
      <option value="">Select a district</option>
      {districts?.map((district, index) => (
        <option key={index} value={district}>
          {district}
        </option>
      ))}
    </select>
  );
};

export default DistrictDropdown;
