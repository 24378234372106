import { apiSlice } from "./apiSlice";

export const clientPaymentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addPayment: builder.mutation({
      query: (payment) => ({
        url: `/api/payments`,
        method: 'POST',
        body: payment,
      }),
      invalidatesTags: ["Payments"],
    }),

    getPaymentById: builder.query({
      query: (id) => ({
        url: `/api/payments/${id}`,
      }),
      providesTags: ["Payments"],
    }),

    getPaymentsByInvoiceId: builder.query({
      query: ({ invoiceId, sortBy = 'createdAt', sortOrder = 'desc', pageNo = 0, pageSize = 25 }) => {
        const params = { invoiceId, sortBy, sortOrder, pageNo, pageSize };
        return {
          url: `/api/payments`,
          params,
        };
      },
      providesTags: ["Payments"],
    }),

    updatePayment: builder.mutation({
      query: ({ id, payment }) => ({
        url: `/api/payments/${id}`,
        method: 'PUT',
        body: payment,
      }),
      invalidatesTags: ["Payments"],
    }),

    deletePayment: builder.mutation({
      query: (id) => ({
        url: `/api/payments/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ["Payments"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useAddPaymentMutation,
  useGetPaymentByIdQuery,
  useGetPaymentsByInvoiceIdQuery,
  useUpdatePaymentMutation,
  useDeletePaymentMutation,
} = clientPaymentApiSlice;
