import { Outlet } from "react-router-dom";
import AlertComponent from "../components/AlertComponent";
import AdminNavBar from "./AdminNavBar";

const AdminLayout = () => {
  return (
    <>
      <AdminNavBar />
      <div>
        <AlertComponent />
        <Outlet />
      </div>
    </>
  );
};

export default AdminLayout;
