import  { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  
  useUpdatePaymentMutation,
  useGetPaymentByIdQuery,
} from "../../redux/clientPaymentApiSlice";
import ClientPaymentForm from "./ClientPaymentForm"; 
import FetchWrapper from "../../components/FetchWrapper";
import { useGetLastMembershipEndDateByClientIdQuery } from "../../redux/clientInvoiceApiSlice";

const ClientEditPayments = () => {
  const { paymentId , clientId} = useParams();
  const { data: paymentData, isLoading: isFetching } = useGetPaymentByIdQuery(paymentId);
  const { data: lastPaymentDate } = useGetLastMembershipEndDateByClientIdQuery(clientId);
  const [updatePayment, { isLoading }] = useUpdatePaymentMutation();
  const [initialData, setInitialData] = useState(null);

  const navigate = useNavigate()

  useEffect(() => {
    if (paymentData) {
      setInitialData({
        ...paymentData,
        lastPaymentDate: lastPaymentDate || null,
        client: { id: clientId },
      });
    }
  }, [paymentData, lastPaymentDate, clientId]);

  const handleSubmit = async (updatedPaymentData) => {
    await updatePayment({ id: paymentId, payment: updatedPaymentData }).unwrap();
    navigate(-1);
  };

  return (
    <FetchWrapper isLoading={isFetching || !initialData}>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Edit Payment</h1>
        <ClientPaymentForm initialData={initialData} onSubmit={handleSubmit} isLoading={isLoading} />
      </div>
    </FetchWrapper>
  );
};

export default ClientEditPayments;
