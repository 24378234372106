import React from "react";
import { useParams } from "react-router-dom";
import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaGlobe,
  FaClock,
  FaBuilding,
  FaStar,
  FaStore,
  FaCalendarAlt,
  FaRegCalendarCheck,
  FaRegClock,
  FaRegStar,
} from "react-icons/fa";
import { useGetGymByIdQuery } from "../../redux/gymApiSlice";
import FetchWrapper from "../../components/FetchWrapper";
import FormCardWrapper from "../../components/FormCardWrapper";
import { facilitiesList } from "./facilitiesList";
import socialMediaIcons from "../../components/SocialMediaIcons";

const GymProfile = () => {
  const { gymId } = useParams();
  const { data: gym, isLoading, isError, error } = useGetGymByIdQuery(gymId);

  return (
    <div className="container mx-auto p-4">
      <FetchWrapper isLoading={isLoading} isError={isError} error={error}>
        {gym && (
          <form className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <BasicInfo gymData={gym} />
            <FacilitiesField gymData={gym} />
            <LocationField gymData={gym} />
            <SocialMediaLinks socialMediaLinks={gym.socialMediaLinks} />
            <ImageUploadSection gymData={gym} />
            <GymPricingPlan pricingPlanDto={gym.pricingPlanDto} />
          </form>
        )}
      </FetchWrapper>
    </div>
  );
};

const BasicInfo = ({ gymData }) => (
  <FormCardWrapper title="Basic Info">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {inputFields.map((field, index) => (
        <div key={index} className="flex items-center">
          <div className="text-gray-700 font-semibold flex items-center">
            {field.icon}
            <span>{field.label}:</span>
          </div>
          <div className="ml-2">{gymData[field.name] || "N/A"}</div>
        </div>
      ))}
      <div className="flex items-center">
        <div className="text-gray-700 font-semibold flex items-center">
          <FaPhone className="text-xl text-green-500 mr-2" />
          <span>Contact Number</span>
        </div>
        <div className="ml-2">{gymData.contactNumber.join(", ") || "N/A"}</div>
      </div>
    </div>
  </FormCardWrapper>
);

const ImageUploadSection = ({ gymData }) => (
  <FormCardWrapper title="Images">
    <div className="flex justify-evenly">
      {gymData.gymLogoImageUrl && (
        <div className="flex flex-col items-center">
          <img
            src={gymData.gymLogoImageUrl}
            alt="Gym Logo"
            className="w-32 h-32"
          />
          <span>Logo Image</span>
        </div>
      )}
      {gymData.wifiScannerImageUrl && (
        <div className="flex flex-col items-center">
          <img
            src={gymData.wifiScannerImageUrl}
            alt="WiFi Scanner"
            className="w-32 h-32"
          />
          <span>WiFi Scanner Image</span>
        </div>
      )}
    </div>
  </FormCardWrapper>
);

const LocationField = ({ gymData }) => (
  <FormCardWrapper title="Location">
    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 md:items-end">
      <div className="flex-1">
        <label className="text-gray-700 flex items-center">
          <FaMapMarkerAlt className="text-yellow-500 mr-2" />
          Latitude
        </label>
        <div className="w-full p-2 border rounded">
          {gymData.latitude ?? "N/A"}
        </div>
      </div>
      <div className="flex-1">
        <label className="text-gray-700 flex items-center">
          <FaMapMarkerAlt className="text-yellow-500 mr-2" />
          Longitude
        </label>
        <div className="w-full p-2 border rounded">
          {gymData.longitude ?? "N/A"}
        </div>
      </div>
    </div>
  </FormCardWrapper>
);

const FacilitiesField = ({ gymData }) => {
  const getFacilityIcon = (facilityName) => {
    const facility = facilitiesList.find((item) => item.name === facilityName);
    return facility ? facility.icon : <FaStar className="text-yellow-500" />;
  };

  return (
    <FormCardWrapper title="Facilities">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {gymData.facilities.map((facility, index) => (
          <div key={index} className="flex items-center space-x-2">
            {getFacilityIcon(facility)}
            <span className="text-gray-800">{facility}</span>
          </div>
        ))}
      </div>
    </FormCardWrapper>
  );
};

const SocialMediaLinks = ({ socialMediaLinks }) => (
  <FormCardWrapper title="Social Media Links">
    <div className="flex flex-col">
      {socialMediaLinks.map((link, index) => {
        // Generate a proper URL from the link
        const url = link.startsWith("http") ? link : `http://${link}`;
        // Extract the base URL for icon matching (simplified example)
        const baseUrl = new URL(url).hostname.replace(/www\.|\.com/g, "");

        return (
          <div key={index} className="flex items-center mb-2">
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2"
            >
              {socialMediaIcons[baseUrl] || (
                <FaGlobe className="text-gray-800" />
              )}
              <span className="text-blue-500 hover:underline">{url}</span>
            </a>
          </div>
        );
      })}
    </div>
  </FormCardWrapper>
);

const GymPricingPlan = ({ pricingPlanDto }) => {
  return (
    <FormCardWrapper title="Pricing Plans">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {pricingPlanDto?.map((plan, index) => (
          <div
            key={index}
            className="p-4 border border-gray-200 rounded shadow-sm"
          >
            <h3 className="font-semibold text-lg mb-2">{plan.planName}</h3>
            <ul className="mt-2 space-y-1">
              <li className="flex items-center">
                <FaCalendarAlt className="text-blue-500 mr-2" />
                Monthly Price: {plan.monthlyPrice ? `Rs ${plan.monthlyPrice}` : "N/A"}
              </li>
              <li className="flex items-center">
                <FaRegCalendarCheck className="text-green-500 mr-2" />
                3-Month Price: {plan.threeMonthPrice ? `Rs ${plan.threeMonthPrice}` : "N/A"}
              </li>
              <li className="flex items-center">
                <FaRegClock className="text-purple-500 mr-2" />
                6-Month Price: {plan.sixMonthPrice ? `Rs ${plan.sixMonthPrice}` : "N/A"}
              </li>
              <li className="flex items-center">
                <FaRegStar className="text-yellow-500 mr-2" />
                12-Month Price: {plan.twelveMonthPrice ? `Rs ${plan.twelveMonthPrice}` : "N/A"}
              </li>
            </ul>
            {plan.extraFeatures && (
              <p className="mt-2 text-gray-600">
                Extra Features: {plan.extraFeatures}
              </p>
            )}
          </div>
        ))}
      </div>
    </FormCardWrapper>
  );
};

const inputFields = [
  {
    label: "Gym Name",
    name: "gymName",
    type: "text",
    icon: <FaBuilding className="text-xl text-blue-500 mr-2" />,
  },
  {
    label: "Email Address",
    name: "emailAddress",
    type: "email",
    icon: <FaEnvelope className="text-xl text-yellow-500 mr-2" />,
  },
  {
    label: "Address",
    name: "address",
    type: "text",
    icon: <FaMapMarkerAlt className="text-xl text-red-500 mr-2" />,
  },
  {
    label: "Branch",
    name: "branch",
    type: "text",
    icon: <FaStore className="text-xl text-red-500 mr-2" />,
  },
  {
    label: "Website",
    name: "websiteURL",
    type: "url",
    icon: <FaGlobe className="text-xl text-blue-500 mr-2" />,
  },
  {
    label: "Operating Hours",
    name: "openingHours",
    type: "text",
    icon: <FaClock className="text-xl text-orange-500 mr-2" />,
  },
];

export default GymProfile;
