import { apiSlice } from "./apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({
        pageNo,
        pageSize,
        sortBy,
        sortOrder,
        name,
        email,
        phone,
        role,
      }) => {
        const params = { pageNo, pageSize, sortBy, sortOrder };
        if (name) params.name = name;
        if (email) params.email = email;
        if (phone) params.phone = phone;
        if (role) params.role = role;
        return {
          url: "/api/users",
          params,
        };
      },
      providesTags: ["Users"],
    }),

    getUserById: builder.query({
      query: (id) => `/api/users/${id}`,
      providesTags: ["Users"],
    }),

    updateUser: builder.mutation({
      query: ({ id, userData }) => ({
        url: `/api/users/${id}`,
        method: "PUT",
        body: userData,
      }),
      invalidatesTags: ["Users"],
    }),
    changePassword: builder.mutation({
      query: ({ userId, oldPassword, newPassword }) => ({
        url: `/api/users/${userId}/change-password`,
        method: "PUT",
        body: { oldPassword, newPassword },
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/api/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useChangePasswordMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApiSlice;
