import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the gym slice
const initialState = {
  gymId: null,
  gymBranch: null,
  gymName: null,
  gymAddress: null,
  gymContact: null,
  gymEmail: null, // Added gymEmail field
  gymLogourl: null,
};

// Create the gym slice
export const gymSlice = createSlice({
  name: "gym",
  initialState,
  reducers: {
    // Action to set gym information
    setGym: (state, action) => {
      const { gymId, gymBranch, gymName, gymAddress, gymContact, gymEmail, gymLogourl } = action.payload;
      state.gymId = gymId;
      state.gymBranch = gymBranch;
      state.gymName = gymName;
      state.gymAddress = gymAddress;
      state.gymContact = gymContact;
      state.gymEmail = gymEmail; // Set gymEmail
      state.gymLogourl = gymLogourl;
    },

    // Action to clear gym information
    clearGym: (state) => {
      console.log("Triggered clearGym");
      return { ...initialState };
    },

    // Action to clear gym state if gymId matches
    clearGymIfMatch: (state, action) => {
      const { gymId } = action.payload;

      if (state.gymId === gymId) {
        state.gymId = null;
        state.gymBranch = null;
        state.gymName = null;
        state.gymAddress = null;
        state.gymContact = null;
        state.gymEmail = null; // Clear gymEmail
        state.gymLogourl = null;
      }
    },
  },
});

// Selectors
export const selectGym = (state) => state.gym;

// Export actions
export const { setGym, clearGym, clearGymIfMatch } = gymSlice.actions;

// Export reducer
export default gymSlice.reducer;
