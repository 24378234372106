import { useSelector } from "react-redux";
import { useCreateClientMutation } from "../../redux/clientApiSlice";
import ClientForm from "./ClientForm"; // Ensure the correct path
import { selectGym } from "../../redux/gymSlice";

const AddClientScreen = () => {
  const [createClient, { isLoading }] = useCreateClientMutation();
  const { gymId } = useSelector(selectGym);

  const initialData = {
    gymId: gymId,
    name: "",
    address: "",
    Ggender: "",
    phone: "",
    email: "",
    emergencyContact: "",
    dateOfBirth: "",
    joinedDate: "",
    notes: "",
  };

  const handleSubmit = async ({ clientDto, file }) => {
    await createClient({ clientDto, file }).unwrap();
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Add New Client</h1>
      <ClientForm
        initialData={initialData}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AddClientScreen;
