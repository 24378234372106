import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetClientByIdQuery } from "../../redux/clientApiSlice";

import FetchWrapper from "../../components/FetchWrapper";
import dayjs from "dayjs";
import {
  FaPhone,
  FaEnvelope,
  FaHome,
  FaBirthdayCake,
  FaCalendarDay,
  FaMoneyCheck,
  FaEdit,
  FaPlusCircle,
  FaUserCheck,
} from "react-icons/fa";
import InvoicesTable from "./invoice/InvoicesTable";
import { useGetInvoicesByClientIdQuery } from "../../redux/clientInvoiceApiSlice";

const ClientProfile = () => {
  const { clientId } = useParams();
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const {
    data: user,
    isLoading: isLoadingUser,
    isError: isErrorUser,
    error: errorUser,
  } = useGetClientByIdQuery(clientId);

  const {
    data: invoices,
    isLoading: isLoadingInvoices,
    isError: isErrorInvoices,
    error: errorInvoices,
  } = useGetInvoicesByClientIdQuery({
    clientId,
    sortBy: "createdAt",
    sortOrder: "desc",
    pageNo,
    pageSize,
  });

  const handlePageChange = (newPage) => {
    setPageNo(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setPageNo(0); // Reset to the first page
  };

  return (
    <div className="container mx-auto mb-5 md:px-2">
      <FetchWrapper
        isLoading={isLoadingUser}
        isError={isErrorUser}
        error={errorUser}
      >
        {user && <UserProfile user={user} />}
      </FetchWrapper>

      <FetchWrapper
        isLoading={isLoadingInvoices}
        isError={isErrorInvoices}
        error={errorInvoices}
        isEmptyList={!isLoadingInvoices && invoices?.content.length === 0}
        emptyListInfo={"invoice"}
      >
        {invoices && invoices.content.length > 0 && (
          <InvoicesTable
            clientId={clientId}
            invoices={invoices}
            pageNo={pageNo}
            pageSize={pageSize}
            totalPages={invoices.paginationResponse.totalPages}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        )}
      </FetchWrapper>
    </div>
  );
};

const UserProfile = ({ user }) => {
  const navigate = useNavigate();

  const calculateDaysLeft = (endDate) => {
    const today = dayjs();
    const expirationDate = dayjs(endDate);
    return expirationDate.diff(today, "day");
  };

  const daysLeft = user.membershipEndDate
    ? calculateDaysLeft(user.membershipEndDate)
    : null;
  const daysLeftText =
    daysLeft >= 0
      ? `${daysLeft} days left`
      : `${Math.abs(daysLeft)} days expired`;
  const daysLeftColor = daysLeft >= 0 ? "text-green-600" : "text-red-600";

  return (
    <div className="flex flex-col items-center">
      <div className="relative w-32 h-32 mb-4">
        {user.imageUrl ? (
          <img
            className="absolute inset-0 w-full h-full object-cover rounded-full border-4 border-transparent bg-gradient-to-r from-gray-300 via-white to-gray-300 p-1 transform transition-transform duration-300 hover:scale-110"
            src={user.imageUrl}
            alt={user.name || "N/A"}
          />
        ) : (
          <div className="absolute inset-0 w-full h-full flex items-center justify-center rounded-full border-4 border-transparent bg-gradient-to-r from-gray-300 via-white to-gray-300 p-1">
            <span className="text-4xl text-gray-700 font-semibold">
              {user.name ? user.name.charAt(0).toUpperCase() : "?"}
            </span>
          </div>
        )}
      </div>

      <h2 className="text-3xl font-semibold text-indigo-600">
        {user.name || "N/A"}
      </h2>
      <p>
        {user.membershipEndDate && (
          <span className={` ${daysLeftColor}`}>
            <FaCalendarDay className="inline mr-1" />
            {daysLeftText}
          </span>
        )}
      </p>

      <div className="mt-6 space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <div className="flex items-center space-x-2">
              <FaPhone className="text-blue-500" />
              <span className="text-blue-800 font-semibold">Phone:</span>
              <span className="text-blue-700">{user.phone || "N/A"}</span>
            </div>
            <div className="flex items-center space-x-2">
              <FaEnvelope className="text-green-500" />
              <span className="text-green-800 font-semibold">Email:</span>
              <span className="text-green-700">{user.email || "N/A"}</span>
            </div>
            <div className="flex items-center space-x-2">
              <FaHome className="text-red-500" />
              <span className="text-red-800 font-semibold">Address:</span>
              <span className="text-red-700">{user.address || "N/A"}</span>
            </div>
            <div className="flex items-center space-x-2">
              <FaCalendarDay className="text-yellow-500" />
              <span className="text-yellow-800 font-semibold">Membership:</span>
              <span className="text-yellow-700">
                {user.membershipEndDate || "N/A"}
              </span>
            </div>
          </div>
          <div>
            <div className="flex items-center space-x-2">
              <FaUserCheck className="text-purple-500" />
              <span className="text-purple-800 font-semibold">
                Emergency Contact:
              </span>
              <span className="text-purple-700">
                {user.emergencyContact || "N/A"}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <FaBirthdayCake className="text-pink-500" />
              <span className="text-pink-800 font-semibold">
                Date of Birth:
              </span>
              <span className="text-pink-700">{user.dateOfBirth || "N/A"}</span>
            </div>
            <div className="flex items-center space-x-2">
              <FaCalendarDay className="text-teal-500" />
              <span className="text-teal-800 font-semibold">Joined Date:</span>
              <span className="text-teal-700">{user.joinedDate || "N/A"}</span>
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <FaMoneyCheck className="text-orange-500" />
          <span className="text-orange-800 font-semibold">Notes:</span>
          <span className="text-orange-700">{user.notes || "N/A"}</span>
        </div>
      </div>
      <div className="mt-8 flex justify-center flex-col md:flex-row">
        <button
          className="bg-purple-500 text-white w-64 py-2 rounded-lg shadow-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-400 mb-4 md:mb-0 md:mr-4"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`edit`);
          }}
        >
          <FaEdit className="inline mr-2" />
          Edit Client
        </button>
        <button
          className="bg-teal-500 text-white w-64 py-2 rounded-lg shadow-md hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-400 "
          onClick={(e) => {
            e.stopPropagation();
            navigate(`add-invoices`);
          }}
        >
          <FaPlusCircle className="inline mr-2" />
          Create Invoice
        </button>
      </div>
    </div>
  );
};

export default ClientProfile;
