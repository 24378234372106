import React from "react";
import { useGetUserByIdQuery } from "../../redux/userApiSlice";
import FetchWrapper from "../../components/FetchWrapper";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/userSlice";
import {
  FaPhone,
  FaUser,
  FaHome,
  FaCity,
  FaSms,
  FaRegCheckCircle,
  FaCalendarAlt,
  FaClock,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import FormCardWrapper from "../../components/FormCardWrapper";

const ProfileScreen = () => {
  const { userId } = useSelector(selectUser);
  const { data: user, isLoading, isError, error } = useGetUserByIdQuery(userId);

  return (
    <div className="container mx-auto p-4">
      <FetchWrapper isLoading={isLoading} isError={isError} error={error}>
        {user && <UserProfile user={user} />}
      </FetchWrapper>
    </div>
  );
};

const UserProfile = ({ user }) => {
  const navigate = useNavigate();
  const paymentDue = user.paymentExpiryDate
    ? dayjs(user.paymentExpiryDate).diff(dayjs(), "days")
    : undefined;
  const formatDate = (date) =>
    date ? dayjs(date).format("MMM D, YYYY") : "N/A";

  const handleEditProfile = () => {
    navigate("edit"); // Adjust the route as needed
  };

  const handleChangePassword = () => {
    navigate("change-password"); // Adjust the route as needed
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
        User Profile
      </h2>

      <FormCardWrapper title={"Profile"}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4 text-gray-700">
          <div className="flex items-center space-x-3">
            <FaUser className="text-xl text-indigo-500" />
            <p>
              <strong>Name:</strong> {user.name}
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaPhone className="text-xl text-green-500" />
            <p>
              <strong>Phone:</strong> {user.phone}
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaHome className="text-xl text-blue-500" />
            <p>
              <strong>Address:</strong> {user.address}
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaCity className="text-xl text-yellow-500" />
            <p>
              <strong>District:</strong> {user.district}
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaSms className="text-xl text-orange-500" />
            <p>
              <strong>Total SMS:</strong> {user.totalSms}
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaRegCheckCircle className="text-xl text-green-500" />
            <p>
              <strong>Verified:</strong> {user.verified ? "Yes" : "No"}
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaCalendarAlt className="text-xl text-red-500" />
            <p>
              <strong>Payment Expiry Date:</strong>{" "}
              {formatDate(user.paymentExpiryDate)}
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaClock className="text-xl text-purple-500" />
            <p>
              <strong>Account Created:</strong> {formatDate(user.createdAt)}
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaClock className="text-xl text-purple-500" />
            <p>
              <strong>Last Updated:</strong> {formatDate(user.updatedAt)}
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaClock className="text-xl text-purple-500" />
            <p>
              <strong>Days Until Payment Due:</strong>{" "}
              {paymentDue >= 0 ? `${paymentDue} days` : "Expired"}
            </p>
          </div>
        </div>
        <div className="mt-6 flex justify-center space-x-4">
          <button
            onClick={handleEditProfile}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Edit Profile
          </button>
          <button
            onClick={handleChangePassword}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Change Password
          </button>
        </div>
      </FormCardWrapper>
    </div>
  );
};

export default ProfileScreen;
