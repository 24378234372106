const DynamicDropdown = ({
  label,
  useQueryHook,
  onSelect,
  value,
  queryParams = {},
}) => {
  const { data, error, isLoading } = useQueryHook(queryParams);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    onSelect(selectedValue);
  };

  // Determine if items are objects or strings
  const items = data?.content ?? data ?? [];

  return (
    <div className="w-full">
      <label className="block text-gray-700">{label}</label>
      <div className="relative">
        {isLoading && <div>Loading {label}...</div>}
        {error && (
          <div>
            Error loading {label}: {error.message}
          </div>
        )}
        {!isLoading && !error && (
          <select
            value={value || ""}
            onChange={handleChange}
            className="block w-full px-3 py-2 mt-1 border rounded"
          >
            <option value="">None</option>
            {items.map((item, index) => (
              // Check if item is an object or a string
              typeof item === 'object' ? (
                <option key={item.id} value={item.id}>
                  {item.planName || item.name || item}
                </option>
              ) : (
                <option key={index} value={item}>
                  {item}
                </option>
              )
            ))}
          </select>
        )}
      </div>
    </div>
  );
};

export default DynamicDropdown;
