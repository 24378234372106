import {  FaCheckCircle, FaExclamationTriangle  } from "react-icons/fa";
import MonthlySMSCountChart from "./MonthlySMSCountChart";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";
import { useGetTotalSMSByUserIdQuery } from "../../redux/smsApiSlice";
import { selectGym } from "../../redux/gymSlice";

const SmsAnalytic = () => {
  const { userId } = useSelector(selectUser);
  const { gymId } = useSelector(selectGym);
  const navigate = useNavigate();

  const { data: totalSmsCredit, isLoadingTotalSmsCredit } =
    useGetTotalSMSByUserIdQuery(userId);

  const handleSms = () => {
    navigate("add");
  };

  return (
    <div className="p-4">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold text-gray-800">SMS Details</h2>
        <button
          onClick={handleSms}
          className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Send SMS
        </button>
      </div>

      <div className="flex flex-col lg:flex-row lg:space-x-4">
        <div className="flex flex-row lg:flex-col lg:space-y-4 lg:w-1/4 space-x-4 lg:space-x-0">
          <BoxCard
            icon={FaCheckCircle}
            value={isLoadingTotalSmsCredit ? "Loading..." : totalSmsCredit}
            text="SMS Remaining"
            bgColor="bg-green-100"
            borderColor="border border-green-200"
            iconColor="text-green-500"
          />
        </div>
        <div className="flex-1 mt-4 lg:mt-0">
          <MonthlySMSCountChart gymId={gymId} />
        </div>
      </div>
    </div>
  );
};


const BoxCard = ({
  icon: Icon,
  value,
  text,
}) => {
  // Check if value is negative
  const isNegative = value < 0;

  // Determine the icon, color, and message based on the value
  const valueColor = isNegative ? "text-red-500" : "text-gray-700";
  const valueMessage = value; // Display the value as is
  const creditMessage = isNegative ? `You are in credit.` : text;

  // Use danger sign icon if value is negative, otherwise use the provided icon
  const DisplayIcon = isNegative ? FaExclamationTriangle : Icon;
  const displayIconColor = isNegative ? "text-red-500" : "text-green-500";

  return (
    <div
      className="flex flex-col items-center justify-between w-40 h-40 p-4 rounded-lg bg-green-100 border border-green-200"
    >
      <DisplayIcon className={`text-3xl ${displayIconColor}`} />
      <div className={`text-2xl font-bold ${valueColor}`}>{valueMessage}</div>
      <div className="text-gray-500 text-sm">{creditMessage}</div>
    </div>
  );
};



export default SmsAnalytic;
