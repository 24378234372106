import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetInvoiceByIdQuery, useUpdateInvoiceMutation } from "../../../redux/clientInvoiceApiSlice";
import FetchWrapper from "../../../components/FetchWrapper";
import InvoiceForm from "./InvoiceForm";


const EditInvoice = () => {
  const { invoiceId } = useParams();
  const { data: invoiceData, isLoading: isFetching } = useGetInvoiceByIdQuery(invoiceId);
  const [updateInvoice, { isLoading }] = useUpdateInvoiceMutation();
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (invoiceData) {
      setInitialData(invoiceData);
    }
  }, [invoiceData]);

  const handleSubmit = async (updatedInvoiceData) => {
    await updateInvoice({ id: invoiceId, invoice: updatedInvoiceData }).unwrap();
    navigate(-1);
  };

  return (
    <FetchWrapper isLoading={isFetching || !initialData}>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Edit Invoice</h1>
        <InvoiceForm initialData={initialData} onSubmit={handleSubmit} isLoading={isLoading} />
      </div>
    </FetchWrapper>
  );
};

export default EditInvoice;
