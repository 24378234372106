import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: null,
  name: null,
  email: null,
  token: null,
  isAuthenticated: false,
  verified: false,
  superAdmin: false,
  adminPanel: true,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      const { name, email, id, token, verified, superAdmin } = action.payload;
      state.name = name;
      state.email = email;
      state.userId = id;
      state.token = token;
      state.isAuthenticated = true;
      state.verified = verified;
      state.superAdmin = superAdmin;
    },
    logout: (state) => {
      state.name = null;
      state.email = null;
      state.userId = null;
      state.token = null;
      state.verified = false;
      state.isAuthenticated = false;
      state.superAdmin = false;
      state.adminPanel = true; // Reset adminPanel state on logout if needed
    },
    // Action to toggle adminPanel between true and false
    toggleAdminPanel: (state) => {
      state.adminPanel = !state.adminPanel;
    },
  },
});

export const selectUser = (state) => state.user;

export const { login, logout, toggleAdminPanel } = userSlice.actions;

export default userSlice.reducer;
